import {Box, Menu, MenuItem, Theme, Typography, useTheme} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {tt} from "../../../../../core/Localization";
import {
    EmployeeJoinedUserResponse,
    FileResponse,
    GpsLocationResponse,
    JobEmployeeDataResponse,
    JobEmployeeStatus,
    JobEmployeeTimesheetItemResponse,
    JobLogResponse,
    JobOfferSeatResponse,
    JobUpdateRepeats,
    LocationPureResponse,
    VisitStatus,
    VisitStatusModifier
} from "../../../../../generated/graphql/graphql";
import MoreFilledIcon from "../../../../../icons/MoreFilledIcon";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import AppListItem from "../../../listItems/AppListItem";
import AppIconButton from "../../../buttons/AppIconButton";
import VisitStatusChip from "../../../chips/VisitStatusChip";
import WorkerEditJobStatusModalBottomSheet from "../../../modals/WorkerEditJobStatusModalBottomSheet";
import React, {Dispatch, SetStateAction, useContext, useId, useMemo, useState} from "react";
import ClockIcon from "../../../../../icons/ClockIcon";
import Icons8Change from "../../../../../icons/Icons8Change";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../../../AppData";
import CloseIcon from "../../../../../icons/CloseIcon";
import Icons8Done from "../../../../../icons/Icons8Done";
import {HideConfirmModal, SetConfirmModal} from "../../../modals/AppModals";
import ListItemAvatarsWidget from "../../ListItemAvatarsWidget";
import JobOfferModalBottomSheet, {IJobOfferData} from "../../../modals/job/JobOfferModalBottomSheet";
import PencilIcon from "../../../../../icons/PencilIcon";
import {IJobDetailEmployeeTimesheetModalProps} from "./VisitDetailContent";
import VisitEmployeeStatusGpsLocation from "./VisitEmployeeStatusGpsLocation";
import {distanceBetweenPoints} from "../../../../../utils/Location";
import {isJobOfferSeatFull} from "../../../../../service/JobService";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import PermissionValid, {hasPermission, hasSomePermissions} from "../../../permissions/PermissionValid";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsTimesheets,
    kPermissionsVisitMyStatus,
    kPermissionsWorkers
} from "../../../../../core/constants";
import TimesheetsTotalChips from "../../../timesheets/TimesheetsTotalChips";
import AppButton from "../../../buttons/AppButton";
import Icons8Waiting from "../../../../../icons/Icons8Waiting";
import {userCanApproveByRoleCascade} from "../../../../../service/PrivilegesService";

const useStyles = makeStyles()((theme: Theme) => ({
    timesheet: {
        textAlign: 'right',
        paddingRight: 16,
    },
    timesheetSecondLine: {
        color: '#6F6F6F',
        fontSize: 14,
    },
    pulsingAnimation: {
        borderRadius: 200,
        boxShadow: '0 0 0 0 rgb(116, 116, 116, 0.7)',
        animation: 'pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)',
        transition: 'all 500ms ease-in-out !important',
        '&:hover': {
            animation: 'none',
        }
    },
    '@keyframes pulsing': {
        'to': {
            boxShadow: '0 0 0 20px rgba(232, 76, 61, 0)',
        },
    },
    jobOfferSeatChip: {
        marginRight: 4,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 70,
    },
    gpsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 70,
    },
    visitStatusChipContainer: {
        display: "flex",
        alignItems: "start",
        '*': {
            maxHeight: '100%',
        }
    },
    unfinishedToSolve: {
        paddingLeft: 70,
        marginBottom: 8,
    },
}));

export interface IJobDetailEmployeeProps {
    visitId: number;
    repeatingDay?: number;
    repeats: JobUpdateRepeats;
    status: VisitStatus;
    canEdit: boolean;
    isRepeating: boolean;
    employee: EmployeeJoinedUserResponse;
    employeeJobData?: JobEmployeeDataResponse;
    employeeTimesheet: JobEmployeeTimesheetItemResponse[];
    existingEmployeeIds: number[];
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal: Dispatch<SetStateAction<boolean>>;
    updateAssignedEmployees: (repeats: JobUpdateRepeats, employeeIds: number[], isRemovalTypeUpdate?: boolean) => Promise<void> | void;
    updateJobEmployeeStatus: (employeeId: number, status: JobEmployeeStatus) => void | Promise<void>;
    ignoreJobEmployeeStatus: (employeeId: number) => Promise<void> | void;
    setRepeats: Dispatch<SetStateAction<JobUpdateRepeats>>;
    jobOfferSeat?: JobOfferSeatResponse;
    onUpdateJobOfferSeat?: (data: IJobOfferData) => void;
    closeJobOfferSeat?: () => void;
    jobOfferSeatExcludeEmployeeIds?: number[];
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    setOpenTimesheetModal?: (props: IJobDetailEmployeeTimesheetModalProps) => void;
    location?: LocationPureResponse | NullOrUndefined;
    jobLogForEmployee?: Array<JobLogResponse> | NullOrUndefined;
    gpsLocationsForEmployee?: Array<GpsLocationResponse> | NullOrUndefined;
    jobEmployeeData?: EmployeeJoinedUserResponse[] | NullOrUndefined;
    files: FileResponse[] | NullOrUndefined;
}

/**
 * Component for Employee on Job Detail.
 */
export default function VisitDetailEmployee(props: IJobDetailEmployeeProps) {
    const {
        visitId,
        repeatingDay,
        repeats,
        status,
        canEdit,
        isRepeating,
        employee,
        employeeJobData,
        employeeTimesheet,
        existingEmployeeIds,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        updateAssignedEmployees,
        updateJobEmployeeStatus,
        ignoreJobEmployeeStatus,
        setRepeats,
        jobOfferSeat,
        onUpdateJobOfferSeat,
        closeJobOfferSeat,
        jobOfferSeatExcludeEmployeeIds,
        onUpdateVisitId,
        setOpenTimesheetModal,
        location,
        jobLogForEmployee,
        gpsLocationsForEmployee,
        jobEmployeeData,
        files,
    } = props;

    const jobOfferSeatCancelled = jobOfferSeat?.cancelled ?? false;

    const appDataContext = useContext(AppDataContext);
    const {storage, darkMode, company, employeeId, employeePermissionsMap, companyEmployeeRole} = appDataContext;
    const gpsLocationTracking = company?.gpsLocationTracking;

    const theme = useTheme();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [editJobStatusModal, setEditJobStatusModal] = useState<boolean>(false);
    const [bottomSheetOffers, setBottomSheetOffers] = useState<boolean>(false);

    const {classes, cx} = useStyles();

    const GPSJSX = useMemo(() => {
        if (employee && location && gpsLocationTracking) {
            const theAddress = location.address;

            if (theAddress && theAddress.latitude && theAddress.longitude && jobLogForEmployee && jobLogForEmployee.length > 0 && gpsLocationsForEmployee && gpsLocationsForEmployee.length > 0) {
                const processing = jobLogForEmployee.sort((a, b) => {
                    return b.createdAt > a.createdAt ? 1 : -1;
                });

                const inProgressLog = processing.find((log) => log.employeeStatus === JobEmployeeStatus.InProgress);
                const doneLog = processing.find((log) => log.employeeStatus === JobEmployeeStatus.Done);

                const inProgressGps = inProgressLog ? gpsLocationsForEmployee.find((gps) => gps.id === inProgressLog.gpsLocationId) : undefined;
                const doneGps = doneLog ? gpsLocationsForEmployee.find((gps) => gps.id === doneLog.gpsLocationId) : undefined;

                const inProgressDistance = inProgressGps && inProgressGps.latitude && inProgressGps.longitude ? distanceBetweenPoints(
                    {latitude: inProgressGps.latitude, longitude: inProgressGps.longitude},
                    {latitude: theAddress.latitude, longitude: theAddress.longitude},
                ) : 0;
                const doneDistance = doneGps && doneGps.latitude && doneGps.longitude ? distanceBetweenPoints(
                    {latitude: doneGps.latitude, longitude: doneGps.longitude},
                    {latitude: theAddress.latitude, longitude: theAddress.longitude},
                ) : 0;

                if (inProgressLog) {
                    return (
                        <Box className={classes.gpsContainer}>
                            <Box pr={0.5}>
                                <VisitEmployeeStatusGpsLocation
                                    status={JobEmployeeStatus.InProgress}
                                    gpsLocation={inProgressGps}
                                    distance={inProgressDistance}
                                    targetAddress={theAddress}
                                />
                            </Box>
                            {doneLog ? (
                                <VisitEmployeeStatusGpsLocation
                                    status={JobEmployeeStatus.Done}
                                    gpsLocation={doneGps}
                                    distance={doneDistance}
                                    targetAddress={theAddress}
                                />
                            ) : undefined}
                        </Box>
                    );
                }
            }
        }
        return undefined;
    }, [employee, jobLogForEmployee, gpsLocationsForEmployee, location, gpsLocationTracking]);

    const descriptionJSX = useMemo(() => {
        if (employee) {
            return UserRoleTitle(employee.role);
        }

        return undefined;
    }, [employee, jobLogForEmployee, gpsLocationsForEmployee, location]);

    if (!employee || !employeeJobData) {
        return null;
    }

    /**
     * Set this Employee status to be ignored by Job status.
     */
    const ignoreEmployeeStatus = () => {
        ignoreJobEmployeeStatus(employee.id);
    };

    /**
     * Remove this Employee and call update Employees to BE.
     */
    const removeEmployee = (repeats: JobUpdateRepeats) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('jobDetailContent.removeWorker.confirmModal.title'),
            subtitle: tt('jobDetailContent.removeWorker.confirmModal.description'),
            confirmationButtonText: tt('common.remove'),
            cancelButtonText: tt('common.close'),
            children: <></>,
            onConfirm: () => {
                HideConfirmModal(appDataContext);

                const newEmployeeIds = existingEmployeeIds.filter(id => id !== employee.id);

                updateAssignedEmployees(repeats, newEmployeeIds, true);
            },
        });
    };

    let timeSheetStatusAllApproved = true;
    let canApprove = false;

    if (employeeTimesheet) {
        timeSheetStatusAllApproved = employeeTimesheet.every((timesheet) => timesheet.approved);

        canApprove = userCanApproveByRoleCascade(
            companyEmployeeRole,
            employee?.role,
            employeePermissionsMap,
        );
    }

    let showWasCanceled = employeeJobData.status === JobEmployeeStatus.CanceledByWorker && !employeeJobData.ignoreForVisitStatus;
    const jobOfferSeatNotFull = employeeJobData.status !== JobEmployeeStatus.JobOffer && jobOfferSeat && !isJobOfferSeatFull(jobOfferSeat);
    const jobOfferSeatCanBeOpen = status !== VisitStatus.Canceled && status !== VisitStatus.Done && status !== VisitStatus.Closed;

    const jobOfferSeatStatusJSX = jobOfferSeatNotFull && !jobOfferSeatCancelled &&
    jobOfferSeatCanBeOpen ? (
        <VisitStatusChip className={classes.jobOfferSeatChip} status={VisitStatus.JobOffer}/>
    ) : undefined;

    const jobOfferSeatJSX = jobOfferSeat ? (
        <ListItemAvatarsWidget
            offerTotalPrice={jobOfferSeat.totalPrice ?? 0}
            substituteCount={!jobOfferSeatCanBeOpen ? 0 : (jobOfferSeat.substituteCount || 0)}
            substituteEmployeeIds={jobOfferSeat.acceptedIds.filter((acceptedId) => acceptedId !== employee.id) || []}
            employeeIds={!isJobOfferSeatFull(jobOfferSeat) ? jobOfferSeat.employeeIds.filter((acceptedId) => acceptedId !== employee.id) : []}
            rejectedEmployeeIds={jobOfferSeat.rejectedIds || []}
            ignoreRejectedEmployees={!jobOfferSeatNotFull}
            jobEmployeeData={jobEmployeeData}
            files={files}
        />
    ) : null;

    const permissionCanEditStatus = (employee.id === employeeId && hasPermission(kPermissionsVisitMyStatus, [kActionUpdate], employeePermissionsMap))
        || (hasPermission(kPermissionsWorkers, [kActionUpdate], employeePermissionsMap) && !hasPermission(kPermissionsVisitMyStatus, [kActionUpdate], employeePermissionsMap));

    return (
        <>
            <AppListItem
                profileImage={
                    UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                }
                title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                description={descriptionJSX}
                variant={"smaller-title"}
                actionWidget={
                    <>
                        {canEdit ? (
                            <>
                                {hasSomePermissions([{
                                    permission: kPermissionsWorkers,
                                    requiredPermissions: [kActionUpdate],
                                }, {
                                    permission: kPermissionsWorkers,
                                    requiredPermissions: [kActionDelete],
                                }, {
                                    permission: kPermissionsTimesheets,
                                    requiredPermissions: [kActionView],
                                }], employeePermissionsMap) || permissionCanEditStatus ? (
                                    <Box
                                        /*className={cx(showWasCanceled ? classes.pulsingAnimation : null)}*/>
                                        <AppIconButton
                                            variant={"greyBg"}
                                            placement={"right"}
                                            tooltip={tt('common.more')}
                                            {...bindTrigger(settingsPopupState)}
                                        >
                                            <MoreFilledIcon/>
                                        </AppIconButton>
                                    </Box>
                                ) : null}

                                <Menu {...bindMenu(settingsPopupState)}>
                                    {jobOfferSeat && !isJobOfferSeatFull(jobOfferSeat) && onUpdateJobOfferSeat ? (
                                        <PermissionValid
                                            permission={kPermissionsWorkers}
                                            requiredPermissions={[kActionUpdate]}
                                        >
                                            <MenuItem key={'offerDetailEditKey'} onClick={() => {
                                                settingsPopupState.close();

                                                setBottomSheetOffers(true);
                                            }}>
                                                <PencilIcon/>
                                                {tt('jobWorker.menu.editJobOfferSeat')}
                                            </MenuItem>
                                        </PermissionValid>
                                    ) : undefined}

                                    {jobOfferSeat && !isJobOfferSeatFull(jobOfferSeat) && closeJobOfferSeat ? (
                                        <PermissionValid
                                            permission={kPermissionsWorkers}
                                            requiredPermissions={[kActionDelete]}
                                        >
                                            <MenuItem key={'offerDetailClosetKey'} onClick={() => {
                                                settingsPopupState.close();

                                                closeJobOfferSeat();
                                            }}>
                                                <CloseIcon/>
                                                {tt('jobWorker.menu.closeJobOfferSeat')}
                                            </MenuItem>
                                        </PermissionValid>
                                    ) : undefined}

                                    {showWasCanceled ? (
                                        <PermissionValid
                                            permission={kPermissionsWorkers}
                                            requiredPermissions={[kActionUpdate]}
                                        >
                                            <MenuItem key={'workerItemIgnoreStatus' + employee.id} onClick={() => {
                                                settingsPopupState.close();

                                                setRecurringConfirmActionCallback(ignoreEmployeeStatus);

                                                if (isRepeating) {
                                                    setRepeats(JobUpdateRepeats.Single);
                                                    ignoreEmployeeStatus();
                                                } else {
                                                    ignoreEmployeeStatus();
                                                }
                                            }}>
                                                <Icons8Done/>
                                                {tt('jobWorker.menu.ignoreState')}
                                            </MenuItem>
                                        </PermissionValid>
                                    ) : undefined}

                                    {setOpenTimesheetModal ? (
                                        <PermissionValid
                                            permission={kPermissionsTimesheets}
                                        >
                                            <MenuItem key={'workerItemTimesheetKey' + employee.id} onClick={() => {
                                                settingsPopupState.close();

                                                setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setOpenTimesheetModal({
                                                    employee,
                                                    employeeJobData,
                                                }));

                                                if (isRepeating && !jobOfferSeat) {
                                                    setRepeats(JobUpdateRepeats.Single);
                                                    setRecurringEditModal(true);
                                                } else {
                                                    setRepeats(JobUpdateRepeats.Single);
                                                    setOpenTimesheetModal({
                                                        employee,
                                                        employeeJobData,
                                                    });
                                                }
                                            }}>
                                                <ClockIcon/>
                                                {tt('jobWorker.menu.editTimesheet')}
                                            </MenuItem>
                                        </PermissionValid>
                                    ) : undefined}

                                    {permissionCanEditStatus ? (
                                        <MenuItem key={'workerItemChangeStatus' + employee.id} onClick={() => {
                                            settingsPopupState.close();

                                            // setRecurringConfirmActionCallback(() => setEditJobStatusModal(true));

                                            if (isRepeating) {
                                                setRepeats(JobUpdateRepeats.Single);
                                                setEditJobStatusModal(true);
                                            } else {
                                                setEditJobStatusModal(true);
                                            }
                                        }}>
                                            <Icons8Change/>
                                            {tt('jobWorker.menu.changeState')}
                                        </MenuItem>
                                    ) : undefined}

                                    <PermissionValid
                                        permission={kPermissionsWorkers}
                                        requiredPermissions={[kActionDelete]}
                                    >
                                        <MenuItem key={'employeeDetailDeleteKey' + employee.id} onClick={() => {
                                            settingsPopupState.close();

                                            setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => removeEmployee(repeats));

                                            if (isRepeating && !jobOfferSeat) {
                                                setRepeats(JobUpdateRepeats.Single);
                                                setRecurringEditModal(true);
                                            } else {
                                                setRepeats(JobUpdateRepeats.Single);
                                                removeEmployee(JobUpdateRepeats.Single);
                                            }
                                        }}>
                                            <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                                            {tt('common.removeFromList')}
                                        </MenuItem>
                                    </PermissionValid>
                                </Menu>
                            </>
                        ) : undefined}
                    </>
                }
                belowListItemWidget={<>
                    {showWasCanceled ? (
                        <Box>
                            <Typography className={classes.unfinishedToSolve}>
                                {tt('visitDetailEmployee.unfinishedToSolve')}
                            </Typography>
                        </Box>
                    ) : undefined}

                    <Box className={classes.chipsContainer}>
                        {showWasCanceled ? (
                            <Box pr={0.5} pb={0.5}>
                                <VisitStatusChip
                                    status={VisitStatus.Unfinished}
                                />
                            </Box>
                        ) : undefined}

                        <Box pr={0.5} pb={0.5} className={classes.visitStatusChipContainer}>
                            <VisitStatusChip
                                employeeStatus={employeeJobData.status}
                            />
                        </Box>

                        {jobOfferSeatStatusJSX}

                        <PermissionValid
                            permission={kPermissionsTimesheets}
                        >
                            <TimesheetsTotalChips
                                timesheets={employeeTimesheet}
                                hideZeroType={true}
                            />
                        </PermissionValid>

                        {!timeSheetStatusAllApproved && setOpenTimesheetModal ? (
                            <Box pr={0.5} pb={0.5}>
                                <AppButton
                                    onClick={
                                        canApprove ?
                                            (e) => {
                                                e.stopPropagation();

                                                /* setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setOpenTimesheetModal({
                                                    employee,
                                                    employeeJobData,
                                                })); */

                                                if (isRepeating && !jobOfferSeat) {
                                                    setRepeats(JobUpdateRepeats.Single);
                                                    /* setRecurringEditModal(true); */
                                                    setOpenTimesheetModal({
                                                        employee,
                                                        employeeJobData,
                                                    });
                                                } else {
                                                    setRepeats(JobUpdateRepeats.Single);
                                                    setOpenTimesheetModal({
                                                        employee,
                                                        employeeJobData,
                                                    });
                                                }
                                            } : undefined
                                    }
                                    variant={"containedGreyThin30"}>
                                    <Box color={kAppColors.primary.main} ml={-1}>
                                        <Icons8Waiting/>
                                    </Box>
                                    {tt('timesheet.status.waitingForApproval')}
                                </AppButton>
                            </Box>
                        ) : undefined}
                    </Box>
                    {GPSJSX}
                </>
                }
            />

            {jobOfferSeatJSX}

            {jobOfferSeat && !isJobOfferSeatFull(jobOfferSeat) && onUpdateJobOfferSeat ? (
                <JobOfferModalBottomSheet
                    title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                    existingEmployeeIds={jobOfferSeat?.employeeIds || []}
                    open={bottomSheetOffers}
                    setOpen={setBottomSheetOffers}
                    data={jobOfferSeat}
                    onSave={onUpdateJobOfferSeat}
                    notificationsCheckbox={true}
                    excludeEmployeeIds={jobOfferSeatExcludeEmployeeIds}
                />
            ) : undefined}

            <WorkerEditJobStatusModalBottomSheet
                open={editJobStatusModal}
                setOpen={setEditJobStatusModal}
                employeeData={employee}
                files={files}
                employeeJobData={employeeJobData}
                onSave={updateJobEmployeeStatus}
            />
        </>
    );
}
