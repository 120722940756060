import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {
    AddressFragment,
    AddressProvider,
    GetLocationProfileInput,
    LocationProfileResponse,
    UpdateLocationDocument,
    UpdateLocationMutation,
    UpdateLocationMutationVariables
} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import ScreenContent from "../../components/screens/ScreenContent";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {locationDetailRoute} from "./LocationDetailScreen";
import ContentPadding from "../../components/paper/ContentPadding";
import AppButton from "../../components/buttons/AppButton";
import {kLocationTypesOptions, LocationNameDisplay, LocationTypeOptionByValue} from "../../../service/LocationService";
import {
    GeocodeAddressToCoordinate,
    GeocodeAddressToCoordinateMapyCZ,
    kDefaultMapProps,
    OnGoogleMapLoaded
} from "../../../service/MapsService";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import GoogleMapReact from "google-map-react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {FlavorByEnvironment} from "../../../flavor-config";
import LocationAddressMarker from "../../components/maps/LocationAddressMarker";
import Icons8Location from "../../../icons/Icons8Location";
import MapyCZ from "../../components/maps/MapyCZ";
import LocationEditShimmer from "../../components/shimmers/screenSpecificShimmers/LocationEditScreenShimmer";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import Debouncer from "../../../utils/Debouncer";
import {kActionDelete, kTopicClients, kTopicLocations, kUserInputDebounce} from "../../../core/constants";
import {processQueryError} from "../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {addressToSingleLine} from "../../../utils/AddressUtils";

export const kLocationEditRoute = '/clients/:clientId/locations/:locationId/edit';

/**
 * Shorthand to create Route url.
 */
export function locationEditRoute(clientId: string | number, locationId: string | number): string {
    return kLocationEditRoute
        .replace(':locationId', `${locationId}`)
        .replace(':clientId', `${clientId}`);
}

export const useStyles = makeStyles()((theme: Theme) => ({
    map: {
        width: '100%',
        height: 280,
        marginBottom: 20,
        borderRadius: 8,
        overflow: "hidden",
    },
    helperText: {
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        marginTop: -16,
        marginLeft: 12,
    },
}));

/**
 * Screen component for Location edit.
 */
export default function LocationEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {company} = appDataContext;

    const {clientId, locationId} = useParams();
    const navigate = useNavigate();

    const {classes} = useStyles();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LocationProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (locationId) {
            restApiGet({
                uri: '/location/profile',
                params: {
                    locationId: parseInt(locationId),
                } as GetLocationProfileInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [locationId]);

    const [mutateUpdate, {
        loading: updateLoading,
    }] = useResettableMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'location-detail',
                text: LocationNameDisplay(data!.location.name, data!.location.type, data!.location.address),
                route: locationDetailRoute(clientId!, locationId!),
            });

            breadcrumbs.push({
                key: 'location-edit',
                text: tt('locationEdit.screen.title'),
                route: locationEditRoute(clientId!, locationId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('locationEdit.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    const [mapInputs, setMapInputs] = useState<IInputsData>({
        type: {
            testId: 'workerTimesheetModalTypeInput',
            value: company?.address?.country === 'cz' ? AddressProvider.MapyCz : AddressProvider.Google,
            label: '',
            type: InputType.ChipSwitch,
            options: [
                {label: tt('common.googleMaps'), value: AddressProvider.Google},
                {label: tt('common.mapyCZ'), value: AddressProvider.MapyCz},
            ]
        },
    });

    const locationTypeOptions = kLocationTypesOptions(true).sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

    const [inputs, setInputs] = useState<IInputsData>({
        address: {
            type: InputType.GooglePlacesAutocomplete,
            label: `${tt('common.address')}*`,
            value: '',
            required: mapInputs.type.value === AddressProvider.Google,
            placeholder: tt('common.addAddress.placeholder'),
            isClearable: true,
            grid: {
                sm: 8,
                xs: 12
            },
            hidden: mapInputs.type.value === AddressProvider.MapyCz,
        },
        mapyczAddress: {
            type: InputType.MapyCZAutocomplete,
            label: `${tt('common.address')}*`,
            value: '',
            required: mapInputs.type.value === AddressProvider.MapyCz,
            grid: {
                sm: 8,
                xs: 12
            },
            hidden: mapInputs.type.value === AddressProvider.Google,
        },
        type: {
            type: InputType.TextAutocomplete,
            label: `${tt('common.type')}*`,
            value: '',
            autocompleteOptions: locationTypeOptions,
            required: true,
            overlayEndSuffixJSX: () => <Box marginTop={1.75} marginRight={1}><Icons8Location/></Box>,
            grid: {
                sm: 4,
                xs: 12
            },
            helperText: <Typography className={classes.helperText}
                                    paragraph={true}>{tt('common.type.helperText')}</Typography>,
        },
        name: {
            type: InputType.ButtonTextField,
            label: tt('common.locationName'),
            toggleButtonText: tt('common.addLocationName'),
            value: '',
        },
        specification: {
            type: InputType.ButtonTextField,
            label: tt('common.specification'),
            minRows: 4,
            toggleButtonText: tt('common.addLocationSpecification'),
            value: '',
        },
        phone: {
            type: InputType.ButtonTextField,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: '',
            required: false,
        },
    });

    useEffect(() => {
        if (mapInputs.type.value === AddressProvider.Google) {
            setInputs(prev => {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        required: true,
                        hidden: false,
                    },
                    mapyczAddress: {
                        ...prev.mapyczAddress,
                        required: false,
                        hidden: true,
                    }
                };
            });
        } else if (mapInputs.type.value === AddressProvider.MapyCz) {
            setInputs(prev => {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        required: false,
                        hidden: true,
                    },
                    mapyczAddress: {
                        ...prev.mapyczAddress,
                        required: true,
                        hidden: false,
                    }
                };
            });
        }
    }, [mapInputs.type.value]);

    useEffect(() => {
        if (data) {
            let theProvider = data!.location.address.provider;
            if (theProvider === AddressProvider.None) {
                theProvider = AddressProvider.Google;
            }

            setMapInputs(prev => {
                return {
                    ...prev,
                    type: {
                        ...prev.type,
                        value: theProvider || (company?.address?.country === 'cz' ? AddressProvider.MapyCz : AddressProvider.Google),
                    },
                };
            });

            if (theProvider !== AddressProvider.MapyCz) {
                setAddressFragment(data!.location.address as any);
            } else {
                setMapyCZAddressFragment(data!.location.address as any);
            }

            setInputs(prev => {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        required: theProvider === AddressProvider.Google,
                        hidden: theProvider === AddressProvider.MapyCz,
                        value: data!.location.address && theProvider !== AddressProvider.MapyCz ? addressToSingleLine(data!.location.address) : '',
                        placeId: data!.location.address && theProvider !== AddressProvider.MapyCz ? data!.location.address.googlePlacesId as any : undefined,
                    },
                    mapyczAddress: {
                        ...prev.mapyczAddress,
                        required: theProvider === AddressProvider.MapyCz,
                        hidden: theProvider === AddressProvider.Google,
                        value: data!.location.address && theProvider === AddressProvider.MapyCz ? addressToSingleLine(data!.location.address) : '',
                    },
                    type: {
                        ...prev.type,
                        value: LocationTypeOptionByValue(data!.location.type) || '',
                        userData: data!.location.icon || '',
                    },
                    name: {
                        ...prev.name,
                        value: data!.location.name || '',
                    },
                    specification: {
                        ...prev.specification,
                        value: data!.location.specification || '',
                    },
                    phone: {
                        ...prev.phone,
                        value: data!.location.phoneNumber || '',
                    },
                };
            });
        }
    }, [data]);

    const [service, setService] = useState<any>();
    const currentAddress = inputs.address?.value || '';
    const mapyczAddress = inputs.mapyczAddress?.value || '';

    const [mapCenter, setMapCenter] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [markerLocation, setMarkerLocation] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapZoom, setMapZoom] = useState<number>(kDefaultMapProps.zoom);
    const [addressFragment, setAddressFragment] = useState<AddressFragment>();

    const [mapyCZMapCenter, setMapyCZMapCenter] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapyCZMarkerLocation, setMapyCZMarkerLocation] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapyCZMapZoom, setMapyCZMapZoom] = useState<number>(kDefaultMapProps.zoom);
    const [mapyCZAddressFragment, setMapyCZAddressFragment] = useState<AddressFragment>();

    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    useEffect(() => {
        if (mapInputs.type.value == AddressProvider.Google) {
            if (inputs.address?.value && inputs.type?.value != '' && addressFragment) {
                if (disabledSubmit) {
                    setDisabledSubmit(false);
                }
            } else if (!inputs.address?.value || inputs.type?.value == '' || !addressFragment) {
                if (!disabledSubmit) {
                    setDisabledSubmit(true);
                }
            }
        }
        if (mapInputs.type.value == AddressProvider.MapyCz) {
            if (inputs.mapyczAddress?.value && inputs.type?.value != '' && mapyCZAddressFragment) {
                if (disabledSubmit) {
                    setDisabledSubmit(false);
                }
            } else if (!inputs.mapyczAddress?.value || inputs.type?.value == '' || !mapyCZAddressFragment) {
                if (!disabledSubmit) {
                    setDisabledSubmit(true);
                }
            }
        }
    }, [inputs, addressFragment, mapyCZAddressFragment, mapInputs.type.value]);

    const addressOnMapTimeout = useRef(new Debouncer(kUserInputDebounce));

    useEffect(() => {
        addressOnMapTimeout.current!.run(() => {
            AddressOnMap({currentAddress, placeId: inputs.address?.placeId})
                .catch(e => console.error(e));
        });

        return () => {
            addressOnMapTimeout.current?.dispose();
        };
    }, [currentAddress]);

    useEffect(() => {
        AddressOnMap({mapyczAddress})
            .catch(e => console.error(e));
    }, [mapyczAddress]);

    /**
     * Geocode address to determine location displayed on map.
     */
    const AddressOnMap = async (params: {
        currentAddress?: string,
        placeId?: string,
        mapyczAddress?: string,
    }) => {
        const theService = service || new window.google.maps.Geocoder();
        if (!service) {
            setService(theService);
        }

        if (params.currentAddress && params.currentAddress.trim().length > 0) {
            const result = await GeocodeAddressToCoordinate({
                service: theService,
                address: params.currentAddress,
                placeId: params.placeId,
            });

            if (result) {
                setMapCenter(result.coordinate);
                setMarkerLocation(result.coordinate);
                setMapZoom(17);
                setAddressFragment(result.addressFragment);
            } else {
                setMapCenter(kDefaultMapProps.center);
                setMarkerLocation(kDefaultMapProps.center);
                setMapZoom(kDefaultMapProps.zoom);
                setAddressFragment(undefined);
            }
        }
        if (params.mapyczAddress) {
            const result = await GeocodeAddressToCoordinateMapyCZ(params.mapyczAddress);

            if (result) {
                setMapyCZMapCenter(result.coordinate);
                setMapyCZMarkerLocation(result.coordinate);
                setMapyCZMapZoom(17);
                setMapyCZAddressFragment(result.addressFragment);
            } else {
                setMapyCZMapCenter(kDefaultMapProps.center);
                setMapyCZMarkerLocation(kDefaultMapProps.center);
                setMapyCZMapZoom(kDefaultMapProps.zoom);
                setMapyCZAddressFragment(undefined);
            }
        }
    };

    /**
     * Mutate Location update to BE.
     */
    const UpdateLocation = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const theFragment = mapInputs.type.value == AddressProvider.Google ? addressFragment : mapyCZAddressFragment;

                theFragment!.id = data!.location.address.id;

                const variables: UpdateLocationMutationVariables = {
                    input: {
                        locationId: parseInt(locationId!),
                        address: theFragment!,
                        type: inputs.type.value.newValue || inputs.type.value.value,
                        icon: inputs.type.userData || '',
                        name: inputs.name.value,
                        specification: inputs.specification.value,
                        phoneNumber: inputs.phone.value,
                    },
                };

                const result = await mutateUpdate({variables});

                if (!result.errors) {
                    navigate(locationDetailRoute(clientId!, locationId!));

                    SuccessToast(tt('locationEdit.screen.success'));
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                clientId={parseInt(clientId!)}
                locationId={parseInt(locationId!)}
                isMobile={isMobile}
                mapInputs={mapInputs}
                setMapInputs={setMapInputs}
                inputs={inputs}
                setInputs={setInputs}
                loading={loading || disabledSubmit}
                updateLoading={updateLoading}
                loadingData={!data && loading}
                updateLocation={UpdateLocation}
                mapCenter={mapCenter}
                markerLocation={markerLocation}
                mapZoom={mapZoom}
                addressFragment={addressFragment}
                mapyCZMapCenter={mapyCZMapCenter}
                mapyCZMarkerLocation={mapyCZMarkerLocation}
                mapyCZMapZoom={mapyCZMapZoom}
                mapyCZAddressFragment={mapyCZAddressFragment}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners() {
    const {clientId, locationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocations,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(locationId!);
                });

                if (doDelete) {
                    if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    clientId: number;
    locationId: number;
    loading: boolean;
    loadingData?: boolean;
    updateLoading?: boolean;
    mapInputs: IInputsData;
    setMapInputs: Dispatch<SetStateAction<IInputsData>>;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    updateLocation: VoidFunction;
    mapCenter: GoogleMapReact.Coords;
    markerLocation: GoogleMapReact.Coords;
    mapZoom: number;
    addressFragment?: AddressFragment;
    mapyCZMapCenter: GoogleMapReact.Coords;
    mapyCZMarkerLocation: GoogleMapReact.Coords;
    mapyCZMapZoom: number;
    mapyCZAddressFragment?: AddressFragment;
}

/**
 * Main body component.
 */
function Body(props: IBodyProps) {
    const {
        isMobile,
        clientId,
        locationId,
        mapInputs,
        setMapInputs,
        inputs,
        setInputs,
        updateLocation,
        loading,
        mapCenter,
        markerLocation,
        mapZoom,
        addressFragment,
        mapyCZMapCenter,
        mapyCZMarkerLocation,
        mapyCZMapZoom,
        mapyCZAddressFragment,
        loadingData,
        updateLoading,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const apiKey = FlavorByEnvironment()!.firebase.apiKey;
    const markerJSX = addressFragment ? (
        <LocationAddressMarker lat={markerLocation.lat} lng={markerLocation.lng}/>
    ) : undefined;
    const mapyCZMarker = useMemo(() => {
        return mapyCZAddressFragment ? [{
            lat: mapyCZMarkerLocation.lat,
            lng: mapyCZMarkerLocation.lng,
        }] : undefined;
    }, [mapyCZAddressFragment]);

    const mapsJSX = mapInputs.type.value === AddressProvider.Google ? (
        <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={(maps) => OnGoogleMapLoaded(appDataContext, maps)}
            bootstrapURLKeys={{
                key: apiKey,
                libraries: ['places']
            }}
            center={mapCenter}
            zoom={mapZoom}>

            {markerJSX}
        </GoogleMapReact>
    ) : mapInputs.type.value === AddressProvider.MapyCz ? (
        <MapyCZ center={mapyCZMapCenter} zoom={mapyCZMapZoom} markers={mapyCZMarker}/>
    ) : undefined;

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar isMobile={isMobile}
                             title={tt('locationEdit.screen.title')}
                             backRoute={locationDetailRoute(clientId, locationId)}
                             cancelIconBackButton={true}/>

                {isMobile ? <AppBreadCrumb/> : null}

                <ContentPadding>
                    {loadingData ?
                        <LocationEditShimmer/>
                        : <>
                            <FormBuilder inputs={mapInputs} setInputs={setMapInputs}/>

                            <div className={classes.map}>
                                {mapsJSX}
                            </div>

                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </>}

                    <AppButton
                        variant={"contained"}
                        fullWidth={true}
                        onClick={updateLocation}
                        disabled={loading}
                        isLoading={updateLoading}
                    >
                        {tt('common.save')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
