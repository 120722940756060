import React, {ReactNode, useContext, useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {
    DeleteJobProtocolFromVisitDocument,
    DeleteJobProtocolFromVisitMutationVariables,
    FileResponse,
    GetVisitJobProtocolsInput,
    JobProtocolJoinedOthersResponsePage, JobProtocolResponse,
    JobUpdateRepeats,
} from "../../../../../../generated/graphql/graphql";
import {AppDataContext} from "../../../../../../AppData";
import {ErrorToast, SuccessToast} from "../../../../../../service/ToastService";
import {tt} from "../../../../../../core/Localization";
import VisitProtocolListItem from "./VisitProtocolListItem";
import {Box, Divider} from "@mui/material";
import ProtocolsTabShimmer from "../../../../shimmers/ProtocolsTabShimmer";
import HeadlineWithButton from "../../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import CreateProtocolBottomSheet from "../../../../modals/protocols/CreateProtocolBottomSheet";
import {processQueryError} from "../../../../../../service/ErrorService";
import Icons8Plus from "../../../../../../icons/Icons8Plus";
import {IOnUpdateVisitIdParams} from "../../../../modals/job/visits/VisitDetailModal";
import {kActionCreate, kActionUpdate, kPermissionsVisitProtocols, kTopicVisits} from "../../../../../../core/constants";
import {RestApiClientContext} from "../../../../../../core/RestApiProvider";
import EditProtocolUnfinishedToSolveModalBottomSheet
    from "../../../../modals/protocols/EditProtocolUnfinishedToSolveModalBottomSheet";

export interface IJobDefectsTabProps {
    visitId: number;
    repeatingDay: number | undefined;
    canEdit: boolean;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    files: FileResponse[] | NullOrUndefined;
}

/**
 * Component used to display JobDefects on Job detail.
 */
export default function VisitProtocolsTab(props: IJobDefectsTabProps) {
    const {visitId, repeatingDay, canEdit, onUpdateVisitId, files} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage} = appDataContext;

    const [createProtocolBottomSheet, setCreateProtocolBottomSheet] = useState<boolean>(false);
    const [captureOnOpen, setCaptureOnOpen] = useState<"user" | "environment">();
    const [editModal, setEditModal] = useState<boolean>(false);
    const [editProtocol, setEditProtocol] = useState<JobProtocolResponse>();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<JobProtocolJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicVisits,
            {
                uri: '/job/visit/protocol/search-joined-others',
                params: {
                    visitId: visitId,
                    repeatingDay,
                } as GetVisitJobProtocolsInput,
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            },
            (notifications) => {
                return notifications.some(notification => {
                    if (notification.action !== kActionUpdate) {
                        return false;
                    }

                    return notification.data.id === visitId &&
                        (notification.data.repeatingDay === repeatingDay || (!notification.data.repeatingDay && !repeatingDay)) || notification.data.repeats === JobUpdateRepeats.ThisAndAllFuture;
                });
            },
        );

        return () => subscription.cancel();
    }, [visitId, repeatingDay]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    const [mutateDeleteJobProtocolFromVisit, {
        loading: deleteJobProtocolLoading,
    }] = useMutation(DeleteJobProtocolFromVisitDocument);

    /**
     * Mutate delete job protocol from Visit.
     */
    const deleteJobProtocol = async (jobProtocolId: number) => {
        try {
            const variables: DeleteJobProtocolFromVisitMutationVariables = {
                input: {
                    jobProtocolId: jobProtocolId,
                },
            };

            const result = await mutateDeleteJobProtocolFromVisit({variables});

            if (!result.errors) {
                SuccessToast(tt('jobProtocol.delete.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    const [protocolsJSX, setProtocolsJSX] = useState<ReactNode>();
    useEffect(() => {
            if (!data && loading) {
                setProtocolsJSX(<ProtocolsTabShimmer/>);
            } else {
                const protocols = (data?.content ? [...data.content] : [])
                    .sort((a, b) => {
                        if (a.createdAt > b.createdAt) {
                            return -1;
                        } else if (a.createdAt < b.createdAt) {
                            return 1;
                        }

                        return 0;
                    });

                setProtocolsJSX(
                    protocols
                        .map((protocol, index) => {
                            const employee = data?.employees.find(employee => employee.id === protocol.employeeId);

                            return (
                                <Box key={protocol.id}>
                                    {index === 0 ? null :
                                        <Box pb={2}><Divider/></Box>
                                    }

                                    <VisitProtocolListItem
                                        index={index}
                                        data={protocol}
                                        files={[
                                            ...(files || []),
                                            ...(data?.files || []),
                                        ]}
                                        employee={employee}
                                        onDelete={deleteJobProtocol}
                                        canEdit={canEdit}
                                        showTrashIcon={true}
                                        onEditUnfinishedToSolve={(open: boolean, protocol: JobProtocolResponse) => {
                                            setEditModal(open);

                                            setEditProtocol(protocol);
                                        }}
                                    />
                                </Box>
                            );
                        })
                );
            }
        }, [data, loading, files, storage.publicUrlsForFiles]
    );

    return (
        <>
            <HeadlineWithButton
                title={tt('common.protocol')}
                iconJSX={<Icons8Plus/>}
                buttonText={tt('common.add')}
                onClick={canEdit ? (e) => {
                    setCreateProtocolBottomSheet(true);
                } : undefined}
                permission={kPermissionsVisitProtocols}
                requiredPermissions={[kActionCreate]}
            />

            {protocolsJSX}

            <CreateProtocolBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                open={createProtocolBottomSheet}
                setOpen={setCreateProtocolBottomSheet}
                captureOnOpen={captureOnOpen}
                onUpdateVisitId={onUpdateVisitId}
            />

            <EditProtocolUnfinishedToSolveModalBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                open={editModal}
                setOpen={setEditModal}
                onUpdateVisitId={onUpdateVisitId}
                data={editProtocol}
            />
        </>
    );
}
