import {Box, Stepper, Theme, Tooltip, Typography} from "@mui/material";
import {
    getEmployeeJobStatusBackgroundColor,
    getEmployeeJobStatusIcon,
    getEmployeeJobStatusTextColor,
    getEmployeeJobStatusTitle,
    getVisitStatusBackgroundColor,
    getVisitStatusIcon,
    getVisitStatusTextColor,
    getVisitStatusTitle,
} from "../../../../../../service/VisitService";
import {tt} from "../../../../../../core/Localization";
import React, {ReactNode, useContext} from "react";
import {JobEmployeeStatus, JobOfferSeatResponse, VisitStatus} from "../../../../../../generated/graphql/graphql";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../../../../AppData";
import ResponsiveContainer from "../../../../screens/ResponsiveContainer";
import VisitStatusChip from "../../../../chips/VisitStatusChip";
import {isJobOfferSeatFull} from "../../../../../../service/JobService";
import Icons8LockOpened from "../../../../../../icons/Icons8LockOpened";
import SingleStepWidget from "./SingleStepWidget";
import {hasPermission} from "../../../../permissions/PermissionValid";
import {kActionView, kPermissionsVisitWorkers} from "../../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        borderRadius: 8,
        padding: 8,
        marginLeft: 16,
        marginRight: 16,
        display: "flex",
        width: '100%',
        justifyContent: "start",
        alignItems: "center",
    },
    blueBtnText1: {
        color: kAppColors.text.white,
        fontSize: 10
    },
    blueBtnText2: {
        color: kAppColors.text.white,
        fontSize: 14,
        fontWeight: 600,
    },
    stepper: {
        marginTop: -8,
        paddingTop: 12,
        '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: 4,
        },
        '.MuiStep-root': {
            minHeight: 66,
        },
        '.MuiStepConnector-line': {
            border: "none",
            height: 2,
            opacity: 0.5,
        },
        '.first .MuiStepConnector-horizontal': {
            display: "none",
        },
    },
    labelContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        '.MuiBox-root': {
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 4,
        }
    },
    switchOffset: {
        marginTop: -18,
        '.MuiFormControlLabel-root': {
            marginRight: 0,
            marginLeft: -20,
        },
        '.MuiFormControlLabel-root .MuiTypography-root': {
            display: "none",
            textTransform: "none",
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: 500,
        }
    },
    stepperHover: {
        cursor: "pointer",
        transition: 'background 0.3s linear',
        '&:hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark")
        }
    }
}));


export interface IVisitStatusButtonProps {
    className?: string;
    status?: VisitStatus;
    employeeStatus?: JobEmployeeStatus;
    // canceledByName?: string;
    // canceledByPosition?: string;
    jobOfferSeats?: JobOfferSeatResponse[] | NullOrUndefined;
    // showWasCanceledByJobOffer?: boolean;
    closeVisitSwitch: ReactNode;
    setUnfinishedToSolveTab: VoidFunction;
    setWorkersTab: Function;
}

export default function VisitStatusButton(props: IVisitStatusButtonProps) {
    const {
        className,
        status,
        employeeStatus,
        // canceledByName,
        // canceledByPosition,
        jobOfferSeats,
        // showWasCanceledByJobOffer,
        closeVisitSwitch,
        setUnfinishedToSolveTab,
        setWorkersTab,
    } = props;

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                status={status}
                employeeStatus={employeeStatus}
                className={className}
                // canceledByPosition={canceledByPosition}
                // canceledByName={canceledByName}
                jobOfferSeats={jobOfferSeats}
                // showWasCanceledByJobOffer={showWasCanceledByJobOffer}
                closeVisitSwitch={closeVisitSwitch}
                setUnfinishedToSolveTab={setUnfinishedToSolveTab}
                setWorkersTab={setWorkersTab}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    className?: string;
    status?: VisitStatus;
    employeeStatus?: JobEmployeeStatus;
    // canceledByName?: string;
    // canceledByPosition?: string;
    jobOfferSeats?: JobOfferSeatResponse[] | NullOrUndefined;
    // showWasCanceledByJobOffer?: boolean;
    closeVisitSwitch: ReactNode;
    setUnfinishedToSolveTab: VoidFunction;
    setWorkersTab: Function;
}

function Body(props: IBodyProps) {
    const {
        className,
        isMobile,
        status,
        employeeStatus,
        // canceledByName,
        // canceledByPosition,
        jobOfferSeats,
        // showWasCanceledByJobOffer,
        closeVisitSwitch,
        setUnfinishedToSolveTab,
        setWorkersTab,
    } = props;
    const appDataContext = useContext(AppDataContext);
    const {darkMode, employeePermissionsMap} = appDataContext;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.button,
        className,
    );

    if (status) {
        let textColor = getVisitStatusTextColor(status, darkMode);

        if (isMobile || status === VisitStatus.Unfinished || status === VisitStatus.Canceled || status === VisitStatus.JobOffer) {
            let descriptionText = getVisitStatusTitle(status);
            /*if (status === VisitStatus.Unfinished && canceledByName) {
                descriptionText = tt('visitDetail.status.description.canceledByWorker').replace('&&worker', canceledByName);
            } else if (status === VisitStatus.Unfinished && showWasCanceledByJobOffer) {
                descriptionText = tt('visitDetail.status.description.canceledByJobOffer');
            } else if (status === VisitStatus.Canceled && canceledByName && canceledByPosition) {
                descriptionText = tt('visitDetail.status.description.canceledByManager').replace('&&manager', canceledByName).replace('&&position', canceledByPosition);
            } else*/ if (status === VisitStatus.JobOffer) {
                descriptionText = getVisitStatusTitle(status);
            }

            return (
                <Box className={theClassName} style={{
                    backgroundColor: getVisitStatusBackgroundColor(status, darkMode),
                    color: textColor,
                    border: status === VisitStatus.Canceled ? `1px solid ${getVisitStatusTextColor(status, darkMode)}` : undefined,
                }}>
                    {getVisitStatusIcon(status)}
                    <Box pr={1}/>
                    <Box>
                        <Typography
                            className={classes.blueBtnText1}
                            style={{
                                color: textColor,
                            }}
                        >
                            {tt('common.status')}
                        </Typography>
                        <Typography
                            className={classes.blueBtnText2}
                            style={{
                                color: textColor,
                            }}
                        >
                            {descriptionText}
                        </Typography>
                    </Box>
                </Box>
            );
        }

        let statuses = [
            VisitStatus.Scheduled,
            VisitStatus.Travelling,
            VisitStatus.InProgress,
            VisitStatus.Done,
        ];

        if (status == VisitStatus.Done) {
            statuses = [
                VisitStatus.Scheduled,
                VisitStatus.Travelling,
                VisitStatus.InProgress,
                VisitStatus.Done,
                VisitStatus.Closed,
            ];
        }

        const completedItemIndex = statuses.indexOf(status);

        const hasJobOfferStatusJSX = status !== VisitStatus.Done && jobOfferSeats && !!jobOfferSeats
            .filter((offerSeat) => !offerSeat.completed && !offerSeat.deleted)
            .find(jobOfferSeat => {
                return !isJobOfferSeatFull(jobOfferSeat);
            }) ? (
            <VisitStatusChip status={VisitStatus.JobOffer}/>
        ) : undefined;

        const showHover = hasPermission(kPermissionsVisitWorkers, [kActionView], employeePermissionsMap) && (status === VisitStatus.Scheduled || status === VisitStatus.Travelling || status === VisitStatus.InProgress);

        const stepperJSX = <Stepper
            className={cx(classes.stepper, showHover ? classes.stepperHover : null)}
            activeStep={2}
            alternativeLabel
        >
            {statuses.map((thisStatus, index) => {
                const isCompleted = index <= completedItemIndex;
                const thisColor = isCompleted ? textColor : kAppColors.text.secondary(darkMode);
                return (
                    <SingleStepWidget
                        key={thisStatus}
                        color={thisColor}
                        index={index}
                        completedItemIndex={completedItemIndex}
                        icon={thisStatus === VisitStatus.Closed ?
                            <Icons8LockOpened/> : getVisitStatusIcon(thisStatus)}
                        labelWidget={<Box className={classes.labelContainer}>
                            {thisStatus === VisitStatus.Closed ?
                                <Box className={classes.switchOffset}>{closeVisitSwitch}</Box>
                                :
                                <Typography
                                    color={thisColor}
                                    fontSize={12}
                                    fontWeight={600}
                                >{getVisitStatusTitle(thisStatus)}</Typography>
                            }

                            {index === completedItemIndex ? hasJobOfferStatusJSX : null}
                        </Box>}
                    />
                );
            })}
        </Stepper>;

        return (
            <Box sx={{width: '100%'}} pt={1}>
                {showHover ?
                    <Box onClick={event => {
                        // @ts-ignore
                        if (status === VisitStatus.Unfinished) {
                            setUnfinishedToSolveTab();
                        } else {
                            setWorkersTab();
                        }
                    }}>
                        <Tooltip title={tt('visitStatusStepper.tooltip')}>
                            {stepperJSX}
                        </Tooltip>
                    </Box>
                    : stepperJSX}
            </Box>
        );
    }

    if (employeeStatus) {
        let textColor = getEmployeeJobStatusTextColor(employeeStatus, darkMode);

        return (
            <Box className={theClassName} style={{
                backgroundColor: getEmployeeJobStatusBackgroundColor(employeeStatus, darkMode),
                color: textColor,
            }}>
                {getEmployeeJobStatusIcon(employeeStatus)}
                <Box pr={1}/>
                <Box>
                    <Typography
                        className={classes.blueBtnText1}
                        style={{
                            color: textColor,
                        }}>
                        {tt('common.status')}
                    </Typography>
                    <Typography
                        className={classes.blueBtnText2}
                        style={{
                            color: textColor,
                        }}>
                        {getEmployeeJobStatusTitle(employeeStatus)}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return null;
}
