import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {tt} from "../../../../../core/Localization";
import AppButton from "../../../buttons/AppButton";
import {
    GetOutputDocumentTemplatesInput,
    GetVisitOutputDocumentConfigurationInput,
    JobUpdateRepeats,
    OutputDocumentResponsePage,
    UpdateVisitOutputDocumentConfigurationInput,
    VisitOutputDocumentConfigurationResponse,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import {processMutationError, processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import {SuccessToast} from "../../../../../service/ToastService";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface IEditVisitOutputDocumentsConfigurationModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    repeats: JobUpdateRepeats;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

/**
 * Modal bottom sheet to edit Visit OutputDocument configuration.
 */
export default function EditVisitOutputDocumentsConfigurationModalBottomSheet(props: IEditVisitOutputDocumentsConfigurationModalBottomSheetProps) {
    const {visitId, repeatingDay, repeats, onUpdateVisitId, open, setOpen, modalAboveModals} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    const [templateInputs, setTemplateInputs] = useState<IInputsData>({});
    const [allowedTemplateIdsFieldKeys, setAllowedTemplateIdsFieldKeys] = useState<string[]>([]);
    const [inputs, setInputs] = useState<IInputsData>({
        description: {
            type: InputType.ButtonTextField,
            toggleButtonText: tt('editVisitOutputDocumentsConfiguration.modal.description.toggleButtonText'),
            label: tt('editVisitOutputDocumentsConfiguration.modal.description.placeholder'),
            value: '',
            minRows: 4,
            showCharCounter: true,
            maxChars: 1000,
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OutputDocumentResponsePage | NullOrUndefined>(null);
    const [loadingConfiguration, setLoadingConfiguration] = useState<boolean>(false);
    const [dataConfiguration, setDataConfiguration] = useState<VisitOutputDocumentConfigurationResponse | NullOrUndefined>(null);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/output-document/template/search',
                fetchPolicy: FetchPolicy.NetworkOnly,
                params: {
                    companyId: companyId!,
                } as GetOutputDocumentTemplatesInput,
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });

            setInputs({
                description: {
                    ...inputs.description,
                    value: '',
                },
            });

            restApiGet({
                uri: '/output-document/visit/configuration',
                fetchPolicy: FetchPolicy.NetworkOnly,
                params: {
                    visitId: visitId,
                    repeatingDay: repeatingDay,
                } as GetVisitOutputDocumentConfigurationInput,
                setLoading: setLoadingConfiguration,
                onData: setDataConfiguration,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open]);

    useEffect(() => {
        if (data) {
            const newInputs: IInputsData = {};
            const newAllowedTemplateIdsFieldKeys: string[] = [];

            data.content.forEach((outputDocumentTemplate, index: number) => {
                newInputs[outputDocumentTemplate.id] = {
                    type: InputType.CheckBox,
                    topLabel: index === 0 ? tt('newJobVisitOutputDocumentsConfiguration.modal.allowedTemplates.topLabel') : undefined,
                    topLabelPaddingNoTop: true,
                    label: tt(outputDocumentTemplate.translationKey),
                    value: dataConfiguration ? dataConfiguration.allowedTemplateIds.includes(outputDocumentTemplate.id) : !dataConfiguration,
                };
                newAllowedTemplateIdsFieldKeys.push(outputDocumentTemplate.id);
            });

            setTemplateInputs(newInputs);
            setAllowedTemplateIdsFieldKeys(newAllowedTemplateIdsFieldKeys);
        } else {
            setTemplateInputs({});
            setAllowedTemplateIdsFieldKeys([]);
        }

        setInputs({
            description: {
                ...inputs.description,
                value: dataConfiguration?.description || '',
            },
        });
    }, [data, dataConfiguration]);

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    /**
     * Update Visit OutputDocument configuration to BE.
     */
    const onSubmit = () => {
        if (ValidateForm(templateInputs, setTemplateInputs) && ValidateForm(inputs, setInputs)) {
            const newAllowedTemplateIds: number[] = [];

            allowedTemplateIdsFieldKeys.forEach((fieldKey) => {
                if (templateInputs[fieldKey].value) {
                    newAllowedTemplateIds.push(parseInt(fieldKey));
                }
            });

            restApiPost({
                uri: '/output-document/visit/configuration',
                params: {
                    visitId: visitId,
                    repeatingDay: repeatingDay,
                    repeats: repeats,
                    allowedTemplateIds: newAllowedTemplateIds,
                    description: inputs.description.value,
                } as UpdateVisitOutputDocumentConfigurationInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSubmitLoading,
                onData: (data: VisitResponse) => {
                    if (data) {
                        SuccessToast(tt('editVisitOutputDocumentsConfiguration.modal.update.success'));

                        if (data!.id) {
                            onUpdateVisitId({
                                visitId: data!.id,
                                repeatingDay: repeatingDay!!,
                            });
                        }

                        setOpen(false);
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }
    };

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}>
            <BottomSheetModalAppbar
                title={tt('editVisitOutputDocumentsConfiguration.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <Box
                className={classes.horizontalPadding}
            >
                <FormBuilder
                    inputs={templateInputs}
                    setInputs={setTemplateInputs}
                />

                <FormBuilder
                    inputs={inputs}
                    setInputs={setInputs}
                />
            </Box>

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={onSubmit}
                    disabled={loading || loadingConfiguration}
                    isLoading={submitLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
