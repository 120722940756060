import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    AddressProvider,
    CreateClientDocument,
    CreateClientMutation,
    CreateClientMutationVariables
} from "../../../generated/graphql/graphql";
import {useNavigate} from "react-router-dom";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {Box, Divider, InputAdornment, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {clientDetailRoute} from "../../screens/clients/ClientDetailScreen";
import DeleteIcon from "../../../icons/DeleteIcon";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import AppAccordion from "../modals/AppAccordion";
import Icons8Invoice from "../../../icons/Icons8Invoice";
import {
    addressToSingleLine,
    countryOptionForCode,
    hasBillingAddress,
    kAllCountriesAsOptions
} from "../../../utils/AddressUtils";
import SearchIcon from "../../../icons/SearchIcon";
import {IAresResponseCompany, loadCompaniesFromAres} from "../../../service/CompanyService";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 21, width: 21}
    },
    switch: {
        marginBottom: 4,
    },
    accordion: {
        '.titleContainer': {
            width: '100%',
            alignItems: "center",
            p: {
                flexGrow: 1,
            }
        }
    },
    desktopOnly: {
        "@media (max-width: 430)": {
            display: "none",
        }
    },
    extendPopOver: {
        width: 735,
        maxWidth: 'calc( 100vw - 32px)',
    },
    helperText: {
        display: 'block',
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        marginTop: -16,
        marginLeft: 12,
        paddingBottom: 20,
    },
}));

export interface ICreateClientModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    navigation?: boolean;
    onCreate?: (id: number) => void;
}

/**
 * Modal component for new Client creation form.
 */
export default function CreateClientModal(props: ICreateClientModalProps) {
    const {open, setOpen, navigation, onCreate} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId, company} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const {classes} = useStyles();

    const [mutateCreate, {
        loading,
    }] = useResettableMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument);

    const [companyClient, setCompanyClient] = useState<boolean>(false);
    const [nameInputs, setNameInputs] = useState<boolean>(false);
    const [validAresCompanies, setValidAresCompanies] = useState<IAresResponseCompany[]>([]);
    const [billingExpanded, setBillingExpanded] = useState<boolean>(false);
    const [inputs, setInputs] = useState<IInputsData>({
        switch: {
            testId: 'createClientFormCompanySwitch',
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('createClientModal.switch'),
            value: companyClient,
            className: classes.switch,
        },
        companyName: {
            testId: 'createClientFormCompanyNameInput',
            type: InputType.TextAutocomplete,
            paperClass: classes.extendPopOver,
            clearOnBlur: false,
            autocompleteOptions: [],
            label: `${tt('clientForm.label.nameAndSurname')}*`,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        country: {
            type: InputType.TextAutocomplete,
            label: `${tt('common.country')}*`,
            placeholder: tt('clientForm.label.country.placeholder'),
            autocompleteOptions: kAllCountriesAsOptions(),
            hideAddNewOption: true,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
        companyPhone: {
            testId: 'createClientFormCompanyPhoneInput',
            type: InputType.Text,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        companyEmail: {
            testId: 'createClientFormCompanyEmailInput',
            type: InputType.ButtonTextField,
            label: tt('common.email'),
            toggleButtonText: tt('common.addEmail'),
            value: '',
            required: false,
            requireEmail: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    const [contactPersonInputs, setContactPersonInputs] = useState<IInputsData>({
        showContactPersonFormHeader: {
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (
                    <Box pt={1}>
                        <AppButton
                            testId={'createClientFormAddNameButton'}
                            sx={{
                                py: 0,
                                height: '36px !important',
                                mb: 2,
                                display: shouldShowContactPersonInputs ? 'none' : 'block'
                            }}
                            onClick={() => setNameInputs(true)}>{tt('common.addContactPerson')}
                        </AppButton>
                        <Box
                            sx={{display: shouldShowContactPersonInputs ? 'block' : 'none'}}
                        >
                            <HeadlineWithButton
                                title={tt('common.contactPerson')}
                                onClick={() => setNameInputs(false)}
                                iconJSX={<Box sx={{color: kAppColors.red.main}}><DeleteIcon/></Box>}
                            />
                        </Box>
                    </Box>
                );
            },
        },
        name: {
            testId: 'createClientFormFirstNameInput',
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: '',
            required: false,
            hidden: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        surname: {
            testId: 'createClientFormLastNameInput',
            type: InputType.Text,
            label: `${tt('common.lastName')}*`,
            value: '',
            required: false,
            hidden: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        phoneNumber: {
            testId: 'createClientFormPhoneInput',
            type: InputType.Text,
            label: tt('common.phone'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        role: {
            testId: 'createClientFormRoleInput',
            type: InputType.ButtonTextField,
            label: tt('common.role'),
            toggleButtonText: tt('common.addRole'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    const [billingDataInputs, setBillingDataInputs] = useState<IInputsData>({
        businessId: {
            testId: 'createClientFormBusinessIdInput',
            type: InputType.TextAutocomplete,
            clearOnBlur: false,
            autocompleteOptions: [],
            label: tt('clientForm.label.businessId'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
            filterOptions: (options, params) => {
                return [...options].filter((option) => option.value.includes(params.inputValue));
            },
        },
        taxId: {
            testId: 'createClientFormTaxIdInput',
            type: InputType.Text,
            label: tt('clientForm.label.taxId'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        street: {
            testId: 'createClientFormStreetInput',
            type: InputType.Text,
            label: tt('clientForm.label.street'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        city: {
            testId: 'createClientFormCityInput',
            type: InputType.Text,
            label: tt('clientForm.label.city'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        zipcode: {
            testId: 'createClientFormZipCodeInput',
            type: InputType.Text,
            label: tt('clientForm.label.zipcode'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        emptySpace: {
            className: classes.desktopOnly,
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (
                    <Box></Box>
                );
            },
            grid: {
                sm: 6,
            },
        },
    });

    const country = inputs.country.value.value || inputs.country.value;

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                companyName: {
                    ...prev.companyName,
                    value: '',
                    autocompleteOptions: [],
                },
            };
        });
        setValidAresCompanies([]);
    }, [country]);

    useEffect(() => {
        if (open) {
            let type = InputType.Text;
            let updateOptionsForQueryName: ((query: string) => void) | undefined;
            let updateOptionsForQueryId: ((query: string) => void) | undefined;
            let innerPrefixJSX: ReactNode | undefined;
            let helperText: ReactNode | undefined;

            if (country === 'cz' && companyClient) {
                type = InputType.TextAutocomplete;

                updateOptionsForQueryName = async (query: string) => {
                    const companies = await loadCompaniesFromAres(appDataContext, {
                        name: query,
                    });

                    setValidAresCompanies(companies);
                    setInputs(prevState => {
                        return {
                            ...prevState,
                            companyName: {
                                ...prevState.companyName,
                                autocompleteOptions: companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.businessId,
                                        description: tt('createClientModal.companyOptionDescription').replace('$businessId', company.businessId).replace('$address', addressToSingleLine(company.address)).trim(),
                                        icon: <Icons8Invoice/>,
                                    };
                                }),
                            },
                        };
                    });
                };
                updateOptionsForQueryId = async (query: string) => {
                    const companies = await loadCompaniesFromAres(appDataContext, {
                        businessId: query,
                    });

                    setValidAresCompanies(companies);
                    setBillingDataInputs(prevState => {
                        return {
                            ...prevState,
                            businessId: {
                                ...prevState.businessId,
                                autocompleteOptions: companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.businessId,
                                        description: tt('createClientModal.companyOptionDescription').replace('$businessId', company.businessId).replace('$address', addressToSingleLine(company.address)).trim(),
                                        icon: <Icons8Invoice/>,
                                    };
                                }),
                            },
                        };
                    });
                };
                innerPrefixJSX = (
                    <InputAdornment position={"start"}>
                        <SearchIcon/>
                    </InputAdornment>
                );
                helperText = <span className={classes.helperText}>{tt('clientForm.label.businessId.helperText')}</span>;
            } else {
                setValidAresCompanies([]);
            }

            setInputs(prevState => {
                return {
                    ...prevState,
                    companyName: {
                        ...prevState.companyName,
                        type: type,
                        updateOptionsForQuery: updateOptionsForQueryName,
                        innerPrefixJSX,
                    },
                };
            });
            setBillingDataInputs(prevState => {
                return {
                    ...prevState,
                    businessId: {
                        ...prevState.businessId,
                        type: type,
                        updateOptionsForQuery: updateOptionsForQueryId,
                        innerPrefixJSX,
                        helperText,
                    },
                };
            });
        }
    }, [companyClient, open, inputs.country.value]);

    useEffect(() => {
        const value = inputs.companyName.value;

        if (value && value.value) {
            const validCompany = validAresCompanies.find(company => company.businessId === value.value);

            if (validCompany) {
                setBillingExpanded(true);

                setInputs(prev => {
                    return {
                        ...prev,
                        country: {
                            ...prev.country,
                            value: countryOptionForCode(validCompany.address.country),
                        },
                    };
                });
                setBillingDataInputs(prev => {
                    return {
                        ...prev,
                        street: {
                            ...prev.street,
                            value: validCompany.address.street,
                        },
                        city: {
                            ...prev.city,
                            value: validCompany.address.city,
                        },
                        zipcode: {
                            ...prev.zipcode,
                            value: validCompany.address.zipcode,
                        },
                        businessId: {
                            ...prev.businessId,
                            value: validCompany.businessId,
                        },
                        taxId: {
                            ...prev.taxId,
                            value: validCompany.taxId,
                        },
                    };
                });
            }
        }
    }, [inputs.companyName.value, validAresCompanies]);

    useEffect(() => {
        const value = billingDataInputs.businessId.value;

        if (value && value.value) {
            const validCompany = validAresCompanies.find(company => company.businessId === value.value);

            if (validCompany) {
                setBillingExpanded(true);

                setInputs(prev => {
                    return {
                        ...prev,
                        companyName: {
                            ...prev.companyName,
                            value: validCompany.name,
                        },
                        country: {
                            ...prev.country,
                            value: countryOptionForCode(validCompany.address.country),
                        },
                    };
                });
                setBillingDataInputs(prev => {
                    return {
                        ...prev,
                        street: {
                            ...prev.street,
                            value: validCompany.address.street,
                        },
                        city: {
                            ...prev.city,
                            value: validCompany.address.city,
                        },
                        zipcode: {
                            ...prev.zipcode,
                            value: validCompany.address.zipcode,
                        },
                        businessId: {
                            ...prev.businessId,
                            value: validCompany.businessId,
                        },
                        taxId: {
                            ...prev.taxId,
                            value: validCompany.taxId,
                        },
                    };
                });
            }
        }
    }, [billingDataInputs.businessId.value, validAresCompanies]);

    /**
     * show if nameInputs on
     * show if name | surname not empty
     * */
    const shouldShowContactPersonInputs = nameInputs || (inputs.name?.value != '' && inputs.name?.value != undefined) || (inputs.surname?.value != '' && inputs.surname?.value != undefined);

    useEffect(() => {
        setInputs(prevState => {
            return {
                ...prevState,
                companyName: {
                    ...prevState.companyName,
                    label: companyClient ? `${tt('clientForm.label.companyName')}*` : `${tt('clientForm.label.nameAndSurname')}*`,
                },
            };
        });

        setContactPersonInputs(prevState => {
            return {
                ...prevState,
                showContactPersonFormHeader:
                    {
                        ...
                            prevState.showContactPersonFormHeader,
                        render: () => {
                            return (
                                <Box pt={1}>
                                    <AppButton
                                        testId={'createClientFormAddNameButton'}
                                        sx={{
                                            py: 0,
                                            height: '36px !important',
                                            mb: 1,
                                            display: shouldShowContactPersonInputs ? 'none' : 'block'
                                        }}
                                        onClick={() => setNameInputs(true)}>{tt('common.addContactPerson')}
                                    </AppButton>
                                    <Box
                                        sx={{
                                            marginLeft: -2,
                                            marginRight: -2,
                                            display: shouldShowContactPersonInputs ? 'block' : 'none'
                                        }}
                                    >
                                        <HeadlineWithButton
                                            title={tt('common.contactPerson')}
                                            onClick={() => setNameInputs(false)}
                                            iconJSX={<Box sx={{color: kAppColors.red.main}}><DeleteIcon/></Box>}
                                        />
                                    </Box>
                                </Box>
                            );
                        },
                    },
                name: {
                    ...
                        prevState.name,
                    required:
                    shouldShowContactPersonInputs,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
                surname: {
                    ...
                        prevState.surname,
                    required:
                    shouldShowContactPersonInputs,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
                phoneNumber: {
                    ...
                        prevState.phoneNumber,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
                role: {
                    ...
                        prevState.role,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
            };
        });

    }, [shouldShowContactPersonInputs, companyClient]);

    useEffect(() => {
        if (inputs.switch && inputs.switch.value !== companyClient) {
            setCompanyClient(inputs.switch.value);
        }
    }, [inputs.switch.value]);

    /**
     * Reset validation, values
     */
    useEffect(() => {
        setCompanyClient(false);
        setInputs(
            prev => {
                return {
                    ...prev,
                    switch: {
                        ...prev.switch,
                        value: false,
                    },
                    companyName: {
                        ...prev.companyName,
                        value: '',
                        error: false,
                        autocompleteOptions: [],
                    },
                    country: {
                        ...prev.country,
                        value: company?.address?.country ? countryOptionForCode(company.address.country) : '',
                        error: false,
                    },
                    companyPhone: {
                        ...prev.companyPhone,
                        value: '',
                        error: false,
                    },
                    companyEmail: {
                        ...prev.companyEmail,
                        value: '',
                        error: false,
                    },
                }
            }
        );

        setContactPersonInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: '',
                    error: false,
                },
                surname: {
                    ...prev.surname,
                    value: '',
                    error: false,
                },
                phoneNumber: {
                    ...prev.phoneNumber,
                    value: '',
                    error: false,
                },
                role: {
                    ...prev.role,
                    value: '',
                    error: false,
                },
            };
        })

        setBillingDataInputs(prev => {
            return {
                ...prev,
                street: {
                    ...prev.street,
                    value: '',
                },
                city: {
                    ...prev.city,
                    value: '',
                },
                zipcode: {
                    ...prev.zipcode,
                    value: '',
                },
                businessId: {
                    ...prev.businessId,
                    value: '',
                },
                taxId: {
                    ...prev.taxId,
                    value: '',
                },
            };
        });
    }, [open]);

    /**
     * Mutate Client to BE and close modal if success.
     */
    const CreateClient = async () => {
        if (ValidateForm(inputs, setInputs) && ValidateForm(billingDataInputs, setBillingDataInputs) && ValidateForm(contactPersonInputs, setContactPersonInputs)) {
            try {
                const country = inputs.country.value.value || inputs.country.value;
                const businessId = billingDataInputs.businessId.value.value || billingDataInputs.businessId.value;

                const billingAddress = {
                    id: undefined,
                    street: billingDataInputs.street.value,
                    city: billingDataInputs.city.value,
                    country,
                    state: '',
                    zipcode: billingDataInputs.zipcode.value,
                    businessId,
                    taxId: billingDataInputs.taxId.value,
                    createdAt: undefined,
                    provider: AddressProvider.None,
                };

                const companyName = inputs.companyName.value.label || inputs.companyName.value;

                const variables: CreateClientMutationVariables = {
                    input: {
                        companyId: companyId!,
                        isCompany: companyClient,
                        companyName: companyName,
                        companyPhone: inputs.companyPhone.value || undefined,
                        name: contactPersonInputs.name.value || undefined,
                        surname: contactPersonInputs.surname.value || undefined,
                        phoneNumber: contactPersonInputs.phoneNumber.value || undefined,
                        role: contactPersonInputs.role.value || undefined,
                        email: inputs.companyEmail.value || undefined,
                        billingAddress: hasBillingAddress(billingAddress) ? billingAddress : undefined,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    setOpen(false);

                    if (theNavigation) {
                        navigate(clientDetailRoute(result.data!.createClient.id));
                    }

                    SuccessToast(tt('createClient.modal.success'));

                    if (onCreate) {
                        onCreate!(result.data!.createClient.id);
                    }
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    const billingFormJSX = useMemo(() => {
        if (companyClient) {
            return (
                <>
                    <Box mr={-2} ml={-2}>
                        <Divider/>
                    </Box>

                    <Box mr={-2} ml={-2}>
                        <AppAccordion
                            className={classes.accordion}
                            summaryText={tt('createClient.modal.label.billingData')}
                            summaryWidget={<Box pr={1}><Icons8Invoice/></Box>}
                            expanded={billingExpanded}
                            setExpanded={setBillingExpanded}
                        >
                            <Box pr={2} pl={2}>
                                <FormBuilder inputs={billingDataInputs} setInputs={setBillingDataInputs}/>
                            </Box>
                        </AppAccordion>
                    </Box>
                </>
            );
        }

        return null;
    }, [billingDataInputs, companyClient, billingExpanded]);

    return (
        <AppModal
            open={open}
            setOpen={setOpen}
            title={tt('common.newClient')}
            fullScreenOnMobile={true}
            blurBackdrop={true}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            {billingFormJSX}

            <Box mr={-2} ml={-2}>
                <Divider/>
            </Box>

            <FormBuilder inputs={contactPersonInputs} setInputs={setContactPersonInputs}/>

            <Box pb={1}/>
            <div className={classes.submitRightContainer}>
                <AppButton
                    className={classes.button}
                    variant={"contained"}
                    fullWidth={false}
                    onClick={CreateClient}
                    isLoading={loading}
                >
                    {tt('common.create')}
                </AppButton>
            </div>
        </AppModal>
    );
}
