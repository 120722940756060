import {makeStyles} from "tss-react/mui";
import {Box, Theme, Tooltip, Typography} from "@mui/material";
import {
    getEmployeeJobStatusBackgroundColor, getEmployeeJobStatusIcon,
    getEmployeeJobStatusTextColor,
    getEmployeeJobStatusTitle,
    getVisitStatusBackgroundColor, getVisitStatusIcon,
    getVisitStatusModifierColor, getVisitStatusModifierIcon,
    getVisitStatusModifierTitle,
    getVisitStatusTextColor,
    getVisitStatusTitle,
} from "../../../service/VisitService";
import React, {ReactNode, useContext} from "react";
import {JobEmployeeStatus, JobLogEvent, VisitStatus, VisitStatusModifier,} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import {kAppColors, kVisitStatusColors} from "../../../styles/AppThemeProcessor";
import {getTimesheetStatusTitle} from "../../../service/TimesheetService";
import {AppDataContext} from "../../../AppData";
import Icons8Waiting from "../../../icons/Icons8Waiting";

export const useStyles = makeStyles()((theme: Theme) => ({
    statusChipContainer: {
        display: "flex",
        justifyContent: "start",
        'svg': {
            height: 20 + 'px !important',
            width: 20 + 'px !important',
        },
    },
}));

export interface IVisitStatusChipProps {
    className?: string;
    status?: VisitStatus;
    employeeStatus?: JobEmployeeStatus;
    statusModifier?: VisitStatusModifier;
    jobLogEvent?: JobLogEvent;
    timeSheetStatusAllApproved?: boolean;
    displayTimeSheetStatusAllApproved?: boolean;
    backgroundColor?: string;
    textColor?: string;
    customValue?: ReactNode | String;
}

/**
 * Component for displaying visit status.
 */
export default function VisitStatusChip(props: IVisitStatusChipProps) {
    const {
        className,
        status,
        employeeStatus,
        backgroundColor,
        textColor,
        statusModifier,
        jobLogEvent,
        timeSheetStatusAllApproved,
        displayTimeSheetStatusAllApproved,
        customValue,
    } = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {theme, darkMode} = appDataContext;

    const theClassName = cx(
        classes.statusChipContainer,
        className,
    );

    if (timeSheetStatusAllApproved !== undefined) {
        if (timeSheetStatusAllApproved && !displayTimeSheetStatusAllApproved) {
            return <Box/>;
        }

        const text = getTimesheetStatusTitle(timeSheetStatusAllApproved);
        let backgroundColor = '';
        let textColor = '';
        let border = 'none';
        let icon;

        if (timeSheetStatusAllApproved) {
            backgroundColor = kVisitStatusColors.done.background(darkMode);
            textColor = kVisitStatusColors.done.text;
        } else if (!timeSheetStatusAllApproved) {
            backgroundColor = 'transparent';
            textColor = kAppColors.primary.main;
            border = `1px solid ${kAppColors.primary.main}`;
            icon = <Box pr={0.5} sx={{color: kAppColors.primary.main}}><Icons8Waiting/></Box>;
        }

        return (
            <Box className={theClassName}>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: 30,
                        borderRadius: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        backgroundColor: backgroundColor,
                        border: border,
                    }}
                >
                    {icon ? icon : null}
                    <Typography
                        style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: textColor,
                        }}>
                        {text}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (jobLogEvent) {
        let text = '';
        let backgroundColor = '';
        let textColor = '';

        if (jobLogEvent === JobLogEvent.EmployeeResponseToJobOfferSeatAccepted) {
            text = tt('job.jobDetailModalBottomSheet.employeeAcceptedJobOffer');
            backgroundColor = kVisitStatusColors.done.background(darkMode);
            textColor = kVisitStatusColors.done.text;
        } else if (jobLogEvent === JobLogEvent.EmployeeResponseToJobOfferSeatRejected) {
            text = tt('job.jobDetailModalBottomSheet.employeeRejectedJobOffer');
            backgroundColor = kVisitStatusColors.unfinished.background(darkMode);
            textColor = kVisitStatusColors.unfinished.text;
        }

        return (
            <Box className={theClassName}>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: 30,
                        borderRadius: 8,
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingRight: 8,
                        paddingLeft: 8,
                        backgroundColor: backgroundColor,
                    }}
                >
                    <Typography
                        style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: textColor,
                        }}>
                        {text}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (statusModifier) {
        return (
            <Box className={theClassName}>
                <Tooltip
                    title={getVisitStatusModifierTitle(statusModifier)}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: 30,
                            borderRadius: 8,
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: customValue && !React.isValidElement(customValue) ? 8 : 2,
                            paddingLeft: 2,
                            background: darkMode ? '#202020' : '#FFFFFF',
                            border: `1px solid ${getVisitStatusModifierColor(statusModifier)}`,
                            color: getVisitStatusModifierColor(statusModifier),
                        }}
                    >
                        {getVisitStatusModifierIcon(statusModifier)}
                        {customValue && React.isValidElement(customValue) ?
                            customValue :
                            (customValue ?
                                <Typography
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: getVisitStatusModifierColor(statusModifier),
                                    }}>
                                    {customValue}
                                </Typography>
                                : null)
                        }
                    </Box>
                </Tooltip>
            </Box>
        );
    }

    if (status) {
        return (
            <Box className={theClassName}>
                <Tooltip
                    title={getVisitStatusTitle(status)}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: 30,
                            borderRadius: 8,
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: customValue && !React.isValidElement(customValue) ? 8 : 2,
                            paddingLeft: 2,
                            color: getVisitStatusTextColor(status, darkMode),
                            backgroundColor: backgroundColor || getVisitStatusBackgroundColor(status, darkMode),
                            border: status == VisitStatus.Closed || status == VisitStatus.Canceled ? `1px solid ${getVisitStatusTextColor(status, darkMode)}` : undefined,
                        }}
                    >
                        {getVisitStatusIcon(status)}
                        {customValue && React.isValidElement(customValue) ?
                            customValue : (customValue ?
                                <Typography
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: textColor || getVisitStatusTextColor(status, darkMode)
                                    }}>
                                    {customValue}
                                </Typography> : null)
                        }
                    </Box>
                </Tooltip>
            </Box>
        );
    }

    if (employeeStatus) {
        return (
            <Box className={theClassName}>
                <Tooltip
                    title={getEmployeeJobStatusTitle(employeeStatus)}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: 30,
                            borderRadius: 8,
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 2,
                            paddingLeft: 2,
                            color: getEmployeeJobStatusTextColor(employeeStatus, darkMode),
                            backgroundColor: backgroundColor || getEmployeeJobStatusBackgroundColor(employeeStatus, darkMode),
                            border: employeeStatus == JobEmployeeStatus.CanceledByManager || employeeStatus == JobEmployeeStatus.CanceledByWorker ? `1px solid ${getEmployeeJobStatusTextColor(employeeStatus, darkMode)}` : undefined,
                        }}
                    >
                        {getEmployeeJobStatusIcon(employeeStatus)}
                    </Box>
                </Tooltip>
            </Box>
        );
    }

    return <Box/>;
}
