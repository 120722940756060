import {Box, Theme} from "@mui/material";
import React from "react";
import AppButton from "../../components/buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import GreyLabel from "../../components/decorations/GreyLabel";
import FileUploadButton from "../../components/storage/FileUploadButton";
import AppIconButton from "../../components/buttons/AppIconButton";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import PermissionValid from "../../components/permissions/PermissionValid";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center",
        minHeight: 52,
    },
    labelContainer: {
        marginRight: "auto",
    },
}));

export interface IHeadlineWithButtonProps {
    className?: string;
    titleClassName?: string;
    title?: string;
    titleJSX?: React.ReactNode;
    buttonText?: string;
    iconJSX?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    button2Text?: string;
    icon2JSX?: React.ReactNode;
    button2OnClick?: React.MouseEventHandler<HTMLButtonElement>;
    button3Text?: string;
    icon3JSX?: React.ReactNode;
    button3OnClick?: React.MouseEventHandler<HTMLButtonElement>;
    button4Text?: string;
    icon4JSX?: React.ReactNode;
    button4OnClick?: React.MouseEventHandler<HTMLButtonElement>;
    fileUploadText?: string;
    iconFileUploadJSX?: React.ReactNode;
    fileAccept?: string;
    fileChosen?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    fileMultiple?: boolean;
    customAction?: React.ReactNode;
    permission?: string;
    requiredPermissions?: string[];
    permission2?: string;
    requiredPermissions2?: string[];
    permission3?: string;
    requiredPermissions3?: string[];
    permission4?: string;
    requiredPermissions4?: string[];
    filePermission?: string;
    fileRequiredPermissions?: string[];
    iconDot?: boolean;
}

export default function HeadlineWithButton(props: IHeadlineWithButtonProps) {
    const {
        className,
        titleClassName,
        title,
        titleJSX,
        buttonText,
        iconJSX,
        onClick,
        button2Text,
        icon2JSX,
        button2OnClick,
        button3Text,
        icon3JSX,
        button3OnClick,
        button4Text,
        icon4JSX,
        button4OnClick,
        fileUploadText,
        iconFileUploadJSX,
        fileAccept,
        fileChosen,
        fileMultiple,
        customAction,
        permission,
        requiredPermissions,
        permission2,
        requiredPermissions2,
        permission3,
        requiredPermissions3,
        permission4,
        requiredPermissions4,
        filePermission,
        fileRequiredPermissions,
        iconDot,
    } = props;

    const {classes, cx} = useStyles();

    let actionJSX = iconJSX && onClick ? (
        <AppIconButton onClick={onClick} color={"primary"} tooltip={buttonText} dot={iconDot}>
            {iconJSX}
        </AppIconButton>
    ) : buttonText && onClick ? (
        <AppButton onClick={onClick} variant={"textThin"}>{buttonText}</AppButton>
    ) : undefined;

    if (actionJSX && permission) {
        actionJSX = (
            <PermissionValid
                permission={permission}
                requiredPermissions={requiredPermissions}
            >
                {actionJSX}
            </PermissionValid>
        );
    }

    let action2JSX = icon2JSX && button2OnClick ? (
        <AppIconButton onClick={button2OnClick} color={"primary"} tooltip={button2Text}>
            {icon2JSX}
        </AppIconButton>
    ) : button2Text && button2OnClick ? (
        <Box pl={1}>
            <AppButton onClick={button2OnClick} variant={"textThin"}>{button2Text}</AppButton>
        </Box>
    ) : undefined;

    if (action2JSX && permission2) {
        action2JSX = (
            <PermissionValid
                permission={permission2}
                requiredPermissions={requiredPermissions2}
            >
                {action2JSX}
            </PermissionValid>
        );
    }

    let action3JSX = icon3JSX && button3OnClick ? (
        <AppIconButton onClick={button3OnClick} color={"primary"} tooltip={button3Text}>
            {icon3JSX}
        </AppIconButton>
    ) : button3Text && button3OnClick ? (
        <Box pl={1}>
            <AppButton onClick={button3OnClick} variant={"textThin"}>{button3Text}</AppButton>
        </Box>
    ) : undefined;

    if (action3JSX && permission3) {
        action3JSX = (
            <PermissionValid
                permission={permission3}
                requiredPermissions={requiredPermissions3}
            >
                {action3JSX}
            </PermissionValid>
        );
    }

    let action4JSX = icon4JSX && button4OnClick ? (
        <AppIconButton onClick={button4OnClick} color={"primary"} tooltip={button4Text}>
            {icon4JSX}
        </AppIconButton>
    ) : button4Text && button4OnClick ? (
        <Box pl={1}>
            <AppButton onClick={button4OnClick} variant={"textThin"}>{button4Text}</AppButton>
        </Box>
    ) : undefined;

    if (action4JSX && permission4) {
        action4JSX = (
            <PermissionValid
                permission={permission4}
                requiredPermissions={requiredPermissions4}
            >
                {action4JSX}
            </PermissionValid>
        );
    }

    let fileUploadJSX = iconFileUploadJSX && fileChosen ? (
        <Box pl={1}>
            <FileUploadButton
                iconJSX={iconFileUploadJSX}
                fileChosen={fileChosen}
                fileAccept={fileAccept}
                multiple={fileMultiple}
                tooltip={fileUploadText}
            />
        </Box>
    ) : fileUploadText && fileChosen ? (
        <Box pl={1}>
            <FileUploadButton text={fileUploadText} fileChosen={fileChosen} fileAccept={fileAccept}
                              multiple={fileMultiple}/>
        </Box>
    ) : undefined;

    if (fileUploadJSX && filePermission) {
        fileUploadJSX = (
            <PermissionValid
                permission={filePermission}
                requiredPermissions={fileRequiredPermissions}
            >
                {fileUploadJSX}
            </PermissionValid>
        );
    }

    return (
        <Box className={cx(classes.container, className)}>
            {title ? (
                <Box className={cx(classes.labelContainer, titleClassName)}>
                    <GreyLabel
                        text={title}
                    />
                </Box>
            ) : undefined}

            {titleJSX}

            {actionJSX}

            {action2JSX}

            {action3JSX}

            {action4JSX}

            {fileUploadJSX}

            {customAction}
        </Box>
    );
}
