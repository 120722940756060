import {
    DeleteOutputDocumentFromVisitInput,
    GetVisitOutputDocumentsInput, JobUpdateRepeats,
    OutputDocumentResponse,
    OutputDocumentResponsePage,
    VisitDetailResponse,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import Icons8Plus from "../../../../../icons/Icons8Plus";
import {tt} from "../../../../../core/Localization";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useId, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import PermissionValid, {hasPermission} from "../../../permissions/PermissionValid";
import {
    kActionCreate,
    kActionDelete,
    kListMaxDisplayItemsLimit,
    kPermissionsVisitOutputDocuments, kPermissionsVisitOutputDocumentsConfiguration
} from "../../../../../core/constants";
import {
    isDefaultVisitOutputDocumentConfiguration,
    subscribeToOutputDocumentResponsePageVisit
} from "../../../../../service/OutputDocumentService";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import AppListItem from "../../../listItems/AppListItem";
import Icons8Document from "../../../../../icons/Icons8Document";
import {DateTime} from "luxon";
import {bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../../../buttons/AppIconButton";
import {bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../../icons/MoreFilledIcon";
import {Box, Menu} from "@mui/material";
import Icons8Download from "../../../../../icons/Icons8Download";
import MenuItem from "@mui/material/MenuItem";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../../../icons/DeleteIcon";
import {HideConfirmModal, SetConfirmModal, SetConfirmModalLoading} from "../../../modals/AppModals";
import ListShimmer from "../../../shimmers/ListShimmer";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import CreateVisitOutputDocumentModalBottomSheet
    from "../../../modals/job/visits/CreateVisitOutputDocumentModalBottomSheet";
import SettingsIcon from "../../../../../icons/SettingsIcon";
import CreateVisitOutputDocumentConfigurationModalBottomSheet
    from "../../../modals/job/visits/CreateVisitOutputDocumentConfigurationModalBottomSheet";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import EditVisitOutputDocumentsConfigurationModalBottomSheet
    from "../../../modals/job/visits/EditVisitOutputDocumentsConfigurationModalBottomSheet";

export interface IVisitDetailOutputDocumentTabProps {
    canEdit: boolean;
    visitId: number;
    repeatingDay?: number;
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal: Dispatch<SetStateAction<boolean>>;
    isRepeating?: boolean;
    repeats: JobUpdateRepeats;
    setRepeats: Dispatch<SetStateAction<JobUpdateRepeats>>;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
}

/**
 * Component for tab of Output Documents for the visit detail view.
 */
export function VisitDetailOutputDocumentTab(props: IVisitDetailOutputDocumentTabProps) {
    const {canEdit, visitId, repeatingDay, setRecurringConfirmActionCallback, setRecurringEditModal, isRepeating, repeats, setRepeats, onUpdateVisitId} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restUriBuilder, restApiDelete} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const canAdd = hasPermission(kPermissionsVisitOutputDocuments, [kActionCreate], employeePermissionsMap);

    const [selectedItem, setSelectedItem] = useState<OutputDocumentResponse>();
    const [createVisitOutputDocumentModalBottomSheetOpen, setCreateVisitOutputDocumentModalBottomSheetOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
    const [createVisitOutputDocumentConfigurationModalBottomSheetOpen, setCreateVisitOutputDocumentConfigurationModalBottomSheetOpen] = useState(false);
    const [configurationBottomSheet, setConfigurationBottomSheet] = useState(false);

    const [loading, setLoading] = useState(true);
    const [outputDocuments, setOutputDocuments] = useState<OutputDocumentResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribeToOutputDocumentResponsePageVisit(
            restApiClientContext,
            appDataContext,
            {
                input: {
                    visitId: visitId,
                    repeatingDay: repeatingDay,
                    includeConfiguration: true,
                } as GetVisitOutputDocumentsInput,
                setLoading,
                onData: setOutputDocuments,
            },
        );

        return () => subscription.cancel();
    }, [visitId, repeatingDay]);

    /**
     * Open edit modal during create OutputDocument process.
     */
    const handleOnEditCreate = (selectedItem: number) => {
        setCreateVisitOutputDocumentModalBottomSheetOpen(false);

        setSelectedTemplateId(selectedItem);

        setCreateVisitOutputDocumentConfigurationModalBottomSheetOpen(true);
    };

    /**
     * Delete Output Document from Visit to BE.
     */
    const deleteOutputDocument = () => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('visitDetail.outputDocumentsTab.document.delete.confirmModal.title'),
            subtitle: tt('visitDetail.outputDocumentsTab.document.delete.confirmModal.subtitle'),
            confirmationButtonText: tt('common.delete'),
            cancelButtonText: tt('common.close'),
            children: <></>,
            onConfirm: () => {
                restApiDelete({
                    uri: '/output-document/visit-delete',
                    params: {
                        outputDocumentId: selectedItem!.id!,
                    } as DeleteOutputDocumentFromVisitInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: (value) => SetConfirmModalLoading(appDataContext, value),
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('visitDetail.outputDocumentsTab.document.delete.success'));
                        }

                        HideConfirmModal(appDataContext);
                    },
                    onError: (error) => processMutationError(error),
                });
            },
        });
    };

    const morePopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [itemsJSX, setItemsJSX] = useState<ReactNode[]>();
    useEffect(() => {
        if (outputDocuments) {
            let newOutputDocuments = [...outputDocuments.content];

            newOutputDocuments.sort((a, b) => b.createdAt - a.createdAt);

            newOutputDocuments = newOutputDocuments.slice(0, kListMaxDisplayItemsLimit);

            setItemsJSX(newOutputDocuments.map((outputDocument) => {
                return (
                    <AppListItem
                        key={outputDocument.id}
                        variant={"smaller-title"}
                        customAvatarInCircle={
                            <Icons8Document/>
                        }
                        title={
                            `${tt(outputDocument.translationKey)} ${tt('visitDetail.outputDocumentsTab.document.sequence').replace('{sequence}', outputDocument.sequenceId)}`
                        }
                        description={DateTime.fromMillis(outputDocument.createdAt).toFormat("d.M.yyyy · H:mm")}
                        actionWidget={
                            <AppIconButton
                                {...bindTrigger(morePopupState)}
                                variant={"greyBg"}
                                placement={"right"}
                                tooltip={tt('common.more')}
                                onClick={(event) => {
                                    setSelectedItem(outputDocument);

                                    morePopupState.open(event);
                                }}
                            >
                                <MoreFilledIcon/>
                            </AppIconButton>
                        }
                    />
                );
            }));
        } else {
            setItemsJSX(undefined);
        }
    }, [outputDocuments, canEdit, morePopupState.isOpen, selectedItem]);

    const iconDot = !isDefaultVisitOutputDocumentConfiguration(outputDocuments?.configuration);

    return (
        <>
            <HeadlineWithButton
                title={tt('common.outputDocuments')}
                titleClassName="gleap-tooltip-output-documents"
                iconJSX={<SettingsIcon/>}
                iconDot={iconDot}
                buttonText={tt('visitDetail.outputDocumentsTab.configuration')}
                onClick={canEdit ? () => {
                    if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                        setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setConfigurationBottomSheet(true));

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            setRecurringEditModal(true);
                        } else {
                            setConfigurationBottomSheet(true);
                        }
                    } else {
                        setConfigurationBottomSheet(true);
                    }
                } : undefined}
                permission={kPermissionsVisitOutputDocumentsConfiguration}
                icon2JSX={<Icons8Plus/>}
                button2OnClick={canEdit && canAdd ? () => {
                    setCreateVisitOutputDocumentModalBottomSheetOpen(true);
                } : undefined}
                button2Text={tt('visitDetail.outputDocumentsTab.addItem')}
            />

            {loading && !outputDocuments ? (
                <ListShimmer items={2}/>
            ) : itemsJSX}

            <Menu {...bindMenu(morePopupState)}>
                <MenuItem
                    key={'downloadItem'}
                    onClick={() => {
                        morePopupState.close();

                        const downloadUrl = restUriBuilder('/firebase/download-file', {fileId: selectedItem!.documentFileId!});

                        window.open(downloadUrl, '_blank');
                    }}>
                    <Icons8Download/>
                    {tt('common.download')}
                </MenuItem>

                {canEdit ? (
                    <PermissionValid
                        permission={kPermissionsVisitOutputDocuments}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem
                            key={'deleteItem'}
                            onClick={() => {
                                morePopupState.close();

                                deleteOutputDocument();
                            }}>
                            <Box color={kAppColors.red.confirmButton}>
                                <DeleteIcon/>
                            </Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                ) : null}
            </Menu>

            <CreateVisitOutputDocumentModalBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                open={createVisitOutputDocumentModalBottomSheetOpen}
                setOpen={setCreateVisitOutputDocumentModalBottomSheetOpen}
                modalAboveModals={true}
                onEditModal={handleOnEditCreate}
            />

            <CreateVisitOutputDocumentConfigurationModalBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                selectedItem={selectedTemplateId || 0}
                open={createVisitOutputDocumentConfigurationModalBottomSheetOpen}
                setOpen={setCreateVisitOutputDocumentConfigurationModalBottomSheetOpen}
                modalAboveModals={true}
            />

            <EditVisitOutputDocumentsConfigurationModalBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                repeats={repeats}
                onUpdateVisitId={onUpdateVisitId}
                open={configurationBottomSheet}
                setOpen={setConfigurationBottomSheet}
                modalAboveModals={true}
            />
        </>
    );
}
