import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {
    CreateVisitOutputDocumentConfigurationInput,
    GetOutputDocumentTemplatesInput, OutputDocumentResponsePage
} from "../../../../../generated/graphql/graphql";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import AppButton from "../../../buttons/AppButton";
import {processQueryError} from "../../../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface INewJobVisitOutputDocumentsConfigurationModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    visitOutputDocumentConfiguration: CreateVisitOutputDocumentConfigurationInput | null;
    setVisitOutputDocumentConfiguration: React.Dispatch<React.SetStateAction<CreateVisitOutputDocumentConfigurationInput | null>>;
}

/**
 * Modal bottom sheet for new Visit Output Documents configuration.
 */
export default function NewJobVisitOutputDocumentsConfigurationModalBottomSheet(props: INewJobVisitOutputDocumentsConfigurationModalBottomSheetProps) {
    const {open, setOpen, visitOutputDocumentConfiguration, setVisitOutputDocumentConfiguration} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    const [templateInputs, setTemplateInputs] = useState<IInputsData>({});
    const [allowedTemplateIdsFieldKeys, setAllowedTemplateIdsFieldKeys] = useState<string[]>([]);
    const [inputs, setInputs] = useState<IInputsData>({});

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/output-document/template/search',
                fetchPolicy: FetchPolicy.NetworkOnly,
                params: {
                    companyId: companyId!,
                } as GetOutputDocumentTemplatesInput,
                setLoading,
                onData: (data: OutputDocumentResponsePage | NullOrUndefined) => {
                    if (data) {
                        const newInputs: IInputsData = {};
                        const newAllowedTemplateIdsFieldKeys: string[] = [];

                        data.content.forEach((outputDocumentTemplate, index: number) => {
                            newInputs[outputDocumentTemplate.id] = {
                                type: InputType.CheckBox,
                                topLabel: index === 0 ? tt('newJobVisitOutputDocumentsConfiguration.modal.allowedTemplates.topLabel') : undefined,
                                topLabelPaddingNoTop: true,
                                label: tt(outputDocumentTemplate.translationKey),
                                value: visitOutputDocumentConfiguration ? visitOutputDocumentConfiguration.allowedTemplateIds.includes(outputDocumentTemplate.id) : !visitOutputDocumentConfiguration,
                            };
                            newAllowedTemplateIdsFieldKeys.push(outputDocumentTemplate.id);
                        });

                        setTemplateInputs(newInputs);
                        setAllowedTemplateIdsFieldKeys(newAllowedTemplateIdsFieldKeys);

                        if (visitOutputDocumentConfiguration) {
                            visitOutputDocumentConfiguration.allowedTemplateIds = visitOutputDocumentConfiguration.allowedTemplateIds.filter((existing) => {
                                return newAllowedTemplateIdsFieldKeys.includes(existing);
                            });

                            setVisitOutputDocumentConfiguration(visitOutputDocumentConfiguration);
                        }
                    } else {
                        setTemplateInputs({});
                        setAllowedTemplateIdsFieldKeys([]);

                        if (visitOutputDocumentConfiguration) {
                            visitOutputDocumentConfiguration.allowedTemplateIds = [];

                            setVisitOutputDocumentConfiguration(visitOutputDocumentConfiguration);
                        }
                    }
                },
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });

            setInputs({
                description: {
                    type: InputType.ButtonTextField,
                    toggleButtonText: tt('newJobVisitOutputDocumentsConfiguration.modal.description.toggleButtonText'),
                    label: tt('newJobVisitOutputDocumentsConfiguration.modal.description.placeholder'),
                    value: visitOutputDocumentConfiguration?.description || '',
                    minRows: 4,
                    showCharCounter: true,
                    maxChars: 1000,
                },
            });
        }
    }, [open]);

    /**
     * Save configuration based on forms inputs.
     */
    const onSubmit = () => {
        if (ValidateForm(templateInputs, setTemplateInputs) && ValidateForm(inputs, setInputs)) {
            const newAllowedTemplateIds: number[] = [];

            allowedTemplateIdsFieldKeys.forEach((fieldKey) => {
                if (templateInputs[fieldKey].value) {
                    newAllowedTemplateIds.push(parseInt(fieldKey));
                }
            });

            setVisitOutputDocumentConfiguration({
                allowedTemplateIds: newAllowedTemplateIds,
                description: inputs.description.value,
            });

            setOpen(false);
        }
    };

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}>
            <BottomSheetModalAppbar
                title={tt('newJobVisitOutputDocumentsConfiguration.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <Box
                className={classes.horizontalPadding}
            >
                <FormBuilder
                    inputs={templateInputs}
                    setInputs={setTemplateInputs}
                />

                <FormBuilder
                    inputs={inputs}
                    setInputs={setInputs}
                />
            </Box>

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={onSubmit}
                    disabled={loading}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
