import {CreateVisitOutputDocumentConfigurationInput} from "../../../../generated/graphql/graphql";
import PermissionValid from "../../permissions/PermissionValid";
import {
    kPermissionsVisitOutputDocuments,
    kPermissionsVisitOutputDocumentsConfiguration
} from "../../../../core/constants";
import {tt} from "../../../../core/Localization";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {Divider} from "@mui/material";
import React, {useMemo, useState} from "react";
import SettingsIcon from "../../../../icons/SettingsIcon";
import NewJobVisitOutputDocumentsConfigurationModalBottomSheet
    from "../../modals/job/visits/NewJobVisitOutputDocumentsConfigurationModalBottomSheet";
import {isDefaultVisitOutputDocumentConfiguration} from "../../../../service/OutputDocumentService";

export interface INewJobVisitOutputDocumentsSectionProps {
    visitOutputDocumentConfiguration: CreateVisitOutputDocumentConfigurationInput | null;
    setVisitOutputDocumentConfiguration: React.Dispatch<React.SetStateAction<CreateVisitOutputDocumentConfigurationInput | null>>;
}

/**
 * Section for setting Output Documents for the Visit.
 */
export default function NewJobVisitOutputDocumentsSection(props: INewJobVisitOutputDocumentsSectionProps) {
    const {visitOutputDocumentConfiguration, setVisitOutputDocumentConfiguration} = props;

    const [configurationBottomSheet, setConfigurationBottomSheet] = useState(false);

    const iconDot = !isDefaultVisitOutputDocumentConfiguration(visitOutputDocumentConfiguration);

    return (
        <PermissionValid
            permission={kPermissionsVisitOutputDocuments}
        >
            <Divider/>

            <HeadlineWithButton
                title={tt('newJobVisitOutputDocuments.title')}
                iconJSX={<SettingsIcon/>}
                iconDot={iconDot}
                buttonText={tt('newJobVisitOutputDocuments.configuration')}
                onClick={() => setConfigurationBottomSheet(true)}
                permission={kPermissionsVisitOutputDocumentsConfiguration}
            />

            <NewJobVisitOutputDocumentsConfigurationModalBottomSheet
                open={configurationBottomSheet}
                setOpen={setConfigurationBottomSheet}
                visitOutputDocumentConfiguration={visitOutputDocumentConfiguration}
                setVisitOutputDocumentConfiguration={setVisitOutputDocumentConfiguration}
            />
        </PermissionValid>
    );
}
