import {Box, Divider, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {
    AssignClientContactsToJobDocument,
    AssignClientContactsToJobMutationVariables,
    ClientPureResponse,
    JobDetailResponse,
    JobUpdateRepeats,
} from "../../../../generated/graphql/graphql";
import {AppDataContext} from "../../../../AppData";
import {useSearchParams} from "react-router-dom";
import {tt} from "../../../../core/Localization";
import {UserFullName} from "../../../../service/UserService";
import AppListItem from "../../listItems/AppListItem";
import UserIcon from "../../../../icons/UserIcon";
import Icons8Company from "../../../../icons/Icons8Company";
import PhoneMenu from "../../menus/PhoneMenu";
import {FormatDateWithIfTodayModification} from "../../../../utils/DateUtils";
import AppTabsComponent from "../../AppTabsComponent";
import CreateContactPersonModal from "../../contactPerson/CreateContactPersonModal";
import {JobNameOrSequenceId} from "../../../../service/JobService";
import JobDetailClientContact from "./JobDetailClientContact";
import {useMutation} from "@apollo/client";
import {processMutationError} from "../../../../service/ErrorService";
import {SuccessToast} from "../../../../service/ToastService";
import JobDetailVisitsTab from "./JobDetailVisitsTab";
import AppAccordion from "../../modals/AppAccordion";
import AppIconButton from "../../buttons/AppIconButton";
import Icons8Plus from "../../../../icons/Icons8Plus";
import Icons8Contacts from "../../../../icons/Icons8Contacts";
import ChooseClientContactModalBottomSheet from "../../modals/job/editJob/ChooseClientContactModalBottomSheet";
import {
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsVisitClientDetails
} from "../../../../core/constants";
import PermissionValid, {hasPermission} from "../../permissions/PermissionValid";
import {displayClientTaxInfoAsText} from "../../../../service/ClientService";

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontSize: 20,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontWeight: "bold",
    },
    removeHorizontalMargins: {
        marginRight: -16,
        marginLeft: -16,
        marginBottom: 16,
    },
    footerText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600
    },
    buttonContainer: {
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
    },
    repeatInfoSectionContainer: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    repeatableInfoSection: {
        paddingTop: 8,
        paddingBottom: 8,
        display: "flex",
        alignItems: "center",
        'p': {
            fontSize: 14,
            fontWeight: 500,
            color: kAppColors.text.primary(theme.palette.mode === "dark")
        }
    },
    closeJobSwitchForm: {
        width: "unset",
        margin: "unset",
    },
}));

export interface IJobDetailContentProps {
    data?: JobDetailResponse | NullOrUndefined;
    jobId: number;
}

let recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined;

export default function JobDetailContent(props: IJobDetailContentProps) {
    const {data, jobId} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const {classes} = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState<string>(searchParams.get('jobDetailContent-selectedTab') ? searchParams.get('jobDetailContent-selectedTab')! : '0');
    const [chooseContactsModal, setChooseContactsModal] = useState(false);
    const [createContactModal, setCreateContactModal] = useState<boolean>(false);

    const jobData = data?.job;
    const clientData: ClientPureResponse | NullOrUndefined = data?.client;
    const existingContactIds = data?.clientContacts.map(contact => contact.id) || [];

    const [mutateAssignClientContactsToJob, {
        loading: assignClientContactsToJobLoading,
    }] = useMutation(AssignClientContactsToJobDocument);

    /**
     * Assign client contacts to job to BE.
     */
    const assignClientContactsToJob = async (clientContactIds: number[], isDeletion?: boolean) => {
        try {
            const variables: AssignClientContactsToJobMutationVariables = {
                input: {
                    jobId: jobId,
                    clientContacts: clientContactIds,
                },
            };

            const result = await mutateAssignClientContactsToJob({variables});

            if (!result.errors) {
                if (isDeletion) {
                    SuccessToast(tt('jobDetail.screen.toast.clientContactsDeleted'));
                } else {
                    SuccessToast(tt('jobDetail.screen.toast.clientContactsUpdated'));
                }

                setCreateContactModal(false);

                setChooseContactsModal(false);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobDetailContent-selectedTab', selectedTab);

            return prev;
        });
    }, [selectedTab]);

    const existingClientContactIds = useMemo(() => {
        return data?.clientContacts.map(contact => contact.id);
    }, [data]);

    const [clientJSX, setClientJSX] = useState<ReactNode | null>(null);
    useEffect(() => {
        const clientContactsCount = data?.clientContacts.length || 0;

        const clientJSX = clientData && (hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap) || hasPermission(kPermissionsVisitClientDetails, [kActionView], employeePermissionsMap)) ? (
            <>
                <Divider/>

                <AppListItem
                    customAvatar={clientData.company ? <Icons8Company/> : <UserIcon/>}
                    title={clientData.name || tt('common.notFilledIn')}
                    // description={clientData.company ? tt('common.companyClient') : undefined}
                    description={displayClientTaxInfoAsText({
                        client: clientData,
                        ignoreTaxId: true,
                        displayBillingAddress: true
                    })}
                    variant={"smaller-title"}
                    actionWidget={
                        <PermissionValid
                            permission={kPermissionsClients}
                        >
                            {clientData.phoneNumber ? <PhoneMenu
                                phone={clientData.phoneNumber}
                                showMarginRight={true}/> : null}
                        </PermissionValid>
                    }
                />
            </>
        ) : null;

        const contactsJSX = hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap) || hasPermission(kPermissionsVisitClientDetails, [kActionView], employeePermissionsMap) ? (
            <>
                <Divider/>

                <AppAccordion
                    summaryText={
                        tt('common.contactPersons.count')
                            .replace('{count}', clientContactsCount.toString())
                    }
                    actionsJSX={
                        <>
                            <PermissionValid
                                permission={kPermissionsClients}
                                requiredPermissions={[kActionUpdate]}
                            >
                                <AppIconButton
                                    onClick={() => setChooseContactsModal(true)}
                                    color="primary"
                                    tooltip={tt('common.select')}
                                >
                                    <Icons8Contacts/>
                                </AppIconButton>
                            </PermissionValid>

                            <PermissionValid
                                permission={kPermissionsClients}
                                requiredPermissions={[kActionUpdate]}
                            >
                                <AppIconButton
                                    onClick={() => setCreateContactModal(true)}
                                    color="primary"
                                    tooltip={tt('jobDetail.screen.button.newContactPerson.label')}
                                >
                                    <Icons8Plus/>
                                </AppIconButton>
                            </PermissionValid>
                        </>
                    }
                    disabled={clientContactsCount === 0}
                >
                    {data?.clientContacts.map(contact => (
                        <JobDetailClientContact
                            key={`contact-${contact.id}`}
                            contact={contact}
                            existingContactIds={existingClientContactIds || []}
                            updateClientContacts={(clientContactIds: number[]) => {
                                assignClientContactsToJob(clientContactIds, true);
                            }}
                        />
                    ))}
                </AppAccordion>
            </>
        ) : null;

        setClientJSX(
            <>
                {clientJSX}

                {contactsJSX}
            </>
        );
    }, [clientData, data, existingClientContactIds, employeePermissionsMap]);

    const [authoredJSX, setAuthoredJSX] = useState<ReactNode | undefined>(undefined);
    useEffect(() => {
        setAuthoredJSX(
            data ? (
                <Box p={2}>
                    <Typography className={classes.footerText}>
                        {tt('jobDetail.screen.footerNote.created')}
                        &nbsp;
                        {FormatDateWithIfTodayModification(undefined, data.job.createdAt)}
                        &nbsp;
                        ·
                        &nbsp;
                        {UserFullName(data.createdByEmployee.name || data.createdByUser.name, data.createdByEmployee.surname || data.createdByUser.surname)}
                    </Typography>
                </Box>
            ) : undefined
        );
    }, [data]);

    const tabsData = [
        {
            label: tt('common.visits'),
            content: (
                <JobDetailVisitsTab
                    jobId={jobId}
                    hasAnyVisit={data?.hasAnyVisit || false}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            ),
        },
    ];

    const mainContentJSX = clientData ? (
        <>
            <Divider/>

            <AppTabsComponent
                controlledValue={selectedTab}
                onTabChange={setSelectedTab}
                data={tabsData}
            />
        </>
    ) : null;

    const modalsJSX = clientData ? (
        <>
            <CreateContactPersonModal
                open={createContactModal}
                setOpen={setCreateContactModal}
                clientId={clientData.id}
                navigation={false}
                onCreate={(id: number) => {
                    assignClientContactsToJob([...(existingClientContactIds || []), id]);
                }}
            />

            <ChooseClientContactModalBottomSheet
                open={chooseContactsModal}
                setOpen={setChooseContactsModal}
                clientId={jobData?.clientId || 0}
                existingContactIds={existingContactIds}
                onSave={(ids: number[]) => {
                    assignClientContactsToJob(ids);
                }}
                canSaveToClear={true}
                updateLoading={assignClientContactsToJobLoading}
                modalAboveModals={true}
            />
        </>
    ) : null;

    return (
        <>
            <Box pl={2} pr={2} pt={1} pb={2}>
                <Typography className={classes.title}>
                    {data ? JobNameOrSequenceId(data.job) : ''}
                </Typography>
            </Box>

            {clientJSX}

            {mainContentJSX}

            {authoredJSX}

            {modalsJSX}
        </>
    );
}
