import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import {
    DeleteJobProtocolFromVisitDocument,
    DeleteJobProtocolFromVisitMutationVariables,
    GetVisitJobProtocolsInput,
    JobEmployeeStatus,
    JobProtocolJoinedOthersResponsePage,
    JobProtocolResponse,
    JobUpdateRepeats,
    VisitDetailResponse,
    VisitStatus
} from "../../../../../generated/graphql/graphql";
import {tt} from "../../../../../core/Localization";
import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import VisitDetailEmployee from "./VisitDetailEmployee";
import ProtocolsTabShimmer from "../../../shimmers/ProtocolsTabShimmer";
import {Box, Divider, Typography} from "@mui/material";
import VisitProtocolListItem from "./visitProtocols/VisitProtocolListItem";
import {
    kActionUpdate,
    kActionView,
    kPermissionsVisitProtocols,
    kPermissionsVisitWorkers,
    kPermissionsWorkers,
    kTopicVisits
} from "../../../../../core/constants";
import {processQueryError} from "../../../../../service/ErrorService";
import {useMutation} from "@apollo/client";
import {ErrorToast, SuccessToast} from "../../../../../service/ToastService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";
import {hasPermission, hasSomePermissions} from "../../../permissions/PermissionValid";
import EmptyListText from "../../../textComponents/EmptyListText";
import VisitStatusChip from "../../../chips/VisitStatusChip";
import EditProtocolUnfinishedToSolveModalBottomSheet
    from "../../../modals/protocols/EditProtocolUnfinishedToSolveModalBottomSheet";

export interface IVisitUnfinishedToSolveTabProps {
    visitId: number;
    repeatingDay: number | undefined;
    canEdit: boolean;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    data: VisitDetailResponse | NullOrUndefined;
    repeats: JobUpdateRepeats;
    setRepeats: Dispatch<SetStateAction<JobUpdateRepeats>>;
    isRepeating: boolean;
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: (((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined)) => void;
    setRecurringEditModal: Dispatch<SetStateAction<boolean>>;
    updateAssignedEmployees: (repeats: JobUpdateRepeats, employeeIds: number[], isRemovalTypeUpdate?: boolean) => Promise<void> | void;
    updateJobEmployeeStatus: (employeeId: number, status: JobEmployeeStatus) => void | Promise<void>;
    ignoreJobEmployeeStatus: (employeeId: number) => Promise<void> | void;
}

/**
 * Tab component for display of Unfinished/ToSolve items of Visit.
 */
export default function VisitUnfinishedToSolveTab(props: IVisitUnfinishedToSolveTabProps) {
    const {
        visitId,
        repeatingDay,
        canEdit,
        onUpdateVisitId,
        data,
        repeats,
        setRepeats,
        isRepeating,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        updateAssignedEmployees,
        updateJobEmployeeStatus,
        ignoreJobEmployeeStatus,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage, employeePermissionsMap} = appDataContext;

    const [editModal, setEditModal] = useState<boolean>(false);
    const [editProtocol, setEditProtocol] = useState<JobProtocolResponse>();

    const canWorkers = hasSomePermissions([
        {permission: kPermissionsWorkers, requiredPermissions: [kActionView]},
        {permission: kPermissionsVisitWorkers, requiredPermissions: [kActionView]},
    ], employeePermissionsMap);
    const canProtocols = hasPermission(kPermissionsVisitProtocols, [kActionView], employeePermissionsMap);

    const [loading, setLoading] = useState<boolean>(false);
    const [protocolsData, setProtocolsData] = useState<JobProtocolJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicVisits,
            {
                uri: '/job/visit/protocol/search-joined-others',
                params: {
                    visitId: visitId,
                    repeatingDay,
                } as GetVisitJobProtocolsInput,
                setLoading,
                onData: setProtocolsData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            },
            (notifications) => {
                return notifications.some(notification => {
                    if (notification.action !== kActionUpdate) {
                        return false;
                    }

                    return notification.data.id === visitId &&
                        (notification.data.repeatingDay === repeatingDay || (!notification.data.repeatingDay && !repeatingDay)) || notification.data.repeats === JobUpdateRepeats.ThisAndAllFuture;
                });
            },
        );

        return () => subscription.cancel();
    }, [visitId, repeatingDay]);

    useEffect(() => {
        if (protocolsData) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(protocolsData.files || []),
                    ],
                };
            });
        }
    }, [protocolsData]);

    const [mutateDeleteJobProtocolFromVisit, {
        loading: deleteJobProtocolLoading,
    }] = useMutation(DeleteJobProtocolFromVisitDocument);

    /**
     * Mutate delete job protocol from Visit.
     */
    const deleteJobProtocol = async (jobProtocolId: number) => {
        try {
            const variables: DeleteJobProtocolFromVisitMutationVariables = {
                input: {
                    jobProtocolId: jobProtocolId,
                },
            };

            const result = await mutateDeleteJobProtocolFromVisit({variables});

            if (!result.errors) {
                SuccessToast(tt('jobProtocol.delete.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    const [unfinishedToSolveEmployeesTotal, setUnfinishedToSolveEmployeesTotal] = useState<number>(0);
    const [protocolsTotal, setProtocolsTotal] = useState<number>(0);
    const [unfinishedToSolveEmployees, setUnfinishedToSolveEmployees] = useState<number>(0);
    const [protocolsCount, setProtocolsCount] = useState<number>(0);

    const [employeesJSX, setEmployeesJSX] = useState<ReactNode>(null);
    useEffect(() => {
        if (data) {
            const visitData = data.visit;
            const repeatingDayData = data.visit.repeatingDayData;
            const assignedEmployeeIds = repeatingDayData ? repeatingDayData.employeeIds : visitData.employeeIds;

            const theVisitStatus = repeatingDayData?.status || visitData?.status || VisitStatus.Scheduled;

            let unfinishedToSolveEmployeesTotal = 0;
            let unfinishedToSolveEmployees = 0;

            setEmployeesJSX(
                assignedEmployeeIds
                    .filter((employeeId) => {
                        let employeeJobData = data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
                        employeeJobData = employeeJobData ? employeeJobData : data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && !employeeJobData.repeatingDay);

                        return employeeJobData?.status == JobEmployeeStatus.CanceledByWorker && employeeJobData?.ignoreForVisitStatus == false;
                    })
                    .map((employeeId) => {
                        const employee = data.employees.find(employee => employee.id === employeeId)!;

                        let employeeJobData = data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
                        employeeJobData = employeeJobData ? employeeJobData : data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && !employeeJobData.repeatingDay);

                        unfinishedToSolveEmployeesTotal++;

                        if (canWorkers) {
                            unfinishedToSolveEmployees++;
                        }

                        return (
                            <VisitDetailEmployee
                                visitId={visitId}
                                repeatingDay={repeatingDay}
                                repeats={repeats}
                                status={theVisitStatus || VisitStatus.Scheduled}
                                key={employeeId}
                                canEdit={canEdit}
                                isRepeating={isRepeating}
                                employee={employee}
                                employeeJobData={employeeJobData}
                                employeeTimesheet={[]}
                                existingEmployeeIds={assignedEmployeeIds || []}
                                setRecurringConfirmActionCallback={setRecurringConfirmActionCallback}
                                setRecurringEditModal={setRecurringEditModal}
                                updateAssignedEmployees={updateAssignedEmployees}
                                updateJobEmployeeStatus={updateJobEmployeeStatus}
                                ignoreJobEmployeeStatus={ignoreJobEmployeeStatus}
                                setRepeats={setRepeats}
                                onUpdateVisitId={onUpdateVisitId}
                                jobEmployeeData={data?.employees}
                                files={data?.files}
                            />
                        );
                    })
            );

            setUnfinishedToSolveEmployeesTotal(unfinishedToSolveEmployeesTotal);
            setUnfinishedToSolveEmployees(unfinishedToSolveEmployees);
        } else {
            setEmployeesJSX(null);

            setUnfinishedToSolveEmployeesTotal(0);
            setUnfinishedToSolveEmployees(0);
        }
    }, [data, repeats, isRepeating, canWorkers]);

    const [protocolsJSX, setProtocolsJSX] = useState<ReactNode>();
    useEffect(() => {
            if (!protocolsData && loading) {
                setProtocolsJSX(<ProtocolsTabShimmer/>);

                setProtocolsTotal(0);
                setProtocolsCount(0);
            } else {
                const protocols = (protocolsData?.content ? [...protocolsData.content] : [])
                    .sort((a, b) => {
                        if (a.createdAt > b.createdAt) {
                            return -1;
                        } else if (a.createdAt < b.createdAt) {
                            return 1;
                        }

                        return 0;
                    });

                let protocolsTotal = 0;
                let protocolsCount = 0;

                setProtocolsJSX(
                    protocols
                        .filter((protocol) => protocol.unfinishedToSolve)
                        .map((protocol, index) => {
                            const employee = protocolsData?.employees.find(employee => employee.id === protocol.employeeId);

                            protocolsTotal++;

                            if (canProtocols) {
                                protocolsCount++;
                            }

                            return (
                                <Box key={protocol.id}>
                                    <VisitProtocolListItem
                                        index={index}
                                        data={protocol}
                                        files={[
                                            ...(data?.files || []),
                                            ...(protocolsData?.files || []),
                                        ]}
                                        employee={employee}
                                        onDelete={deleteJobProtocol}
                                        canEdit={canEdit}
                                        showTrashIcon={true}
                                        onEditUnfinishedToSolve={(open: boolean, protocol: JobProtocolResponse) => {
                                            setEditModal(open);

                                            setEditProtocol(protocol);
                                        }}
                                    />
                                </Box>
                            );
                        })
                );

                setProtocolsTotal(protocolsTotal);
                setProtocolsCount(protocolsCount);
            }
        }, [protocolsData, loading, data, storage.publicUrlsForFiles, canProtocols]
    );

    const hintForStuck = (unfinishedToSolveEmployees + protocolsCount) == 0;

    return (
        <>
            <Box pb={2}/>

            {(unfinishedToSolveEmployeesTotal + protocolsTotal) === 0 && !hintForStuck && !loading ? (
                <EmptyListText text={tt('visitUnfinishedToSolve.emptyListMessage')}/>
            ) : null}

            {hintForStuck && !loading ? (
                <Box pl={2} pr={2} pb={2}>
                    <Typography pb={2}>
                        {tt('visitUnfinishedToSolve.hintForStuck')}
                    </Typography>

                    <Box pr={0.5} pb={0.5}>
                        <VisitStatusChip
                            status={VisitStatus.Unfinished}
                        />
                    </Box>
                </Box>
            ) : null}

            {employeesJSX}

            {unfinishedToSolveEmployees > 0 && protocolsCount > 0 && !loading ? (
                <Box pb={2}><Divider/></Box>
            ) : null}

            {protocolsJSX}

            <EditProtocolUnfinishedToSolveModalBottomSheet
                visitId={visitId}
                repeatingDay={repeatingDay}
                open={editModal}
                setOpen={setEditModal}
                onUpdateVisitId={onUpdateVisitId}
                data={editProtocol}
            />
        </>
    );
}
