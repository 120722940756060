import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";
import {
    AddOutputDocumentToVisitInput,
    GetVisitOutputDocumentsInput,
    VisitOutputDocumentConfigurationResponse
} from "../../../../../generated/graphql/graphql";
import {processMutationError, processQueryError} from "../../../../../service/ErrorService";
import {SuccessToast} from "../../../../../service/ToastService";
import {tt} from "../../../../../core/Localization";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import AppButton from "../../../buttons/AppButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface ICreateVisitOutputDocumentConfigurationModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    selectedItem: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

/**
 * Modal bottom sheet component to modify OutputDocument and add to Visit.
 */
export default function CreateVisitOutputDocumentConfigurationModalBottomSheet(props: ICreateVisitOutputDocumentConfigurationModalBottomSheetProps) {
    const {visitId, repeatingDay, selectedItem, open, setOpen, modalAboveModals} = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const [inputs, setInputs] = useState<IInputsData>({
        description: {
            type: InputType.Text,
            label: tt('newJobVisitOutputDocumentsConfiguration.modal.description.placeholder'),
            value: '',
            minRows: 4,
            showCharCounter: true,
            maxChars: 1000,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs({
                description: {
                    ...inputs.description,
                    value: '',
                },
            });
        }
    }, [open]);

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/output-document/visit/configuration',
                params: {
                    visitId: visitId,
                    repeatingDay: repeatingDay,
                } as GetVisitOutputDocumentsInput,
                setLoading,
                onData: (data: VisitOutputDocumentConfigurationResponse | NullOrUndefined) => {
                    if (data) {
                        setInputs({
                            description: {
                                ...inputs.description,
                                value: data.description,
                            },
                        });
                    }
                },
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Create Output Document to Visit to BE.
     */
    const createOutputDocument = () => {
        if (ValidateForm(inputs, setInputs)) {
            restApiPost({
                uri: '/output-document/add-to-visit',
                params: {
                    visitId: visitId,
                    repeatingDay: repeatingDay,
                    templateId: selectedItem,
                    language: language,
                    description: inputs.description.value,
                } as AddOutputDocumentToVisitInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSubmitLoading,
                onData: (data) => {
                    if (data) {
                        SuccessToast(tt('createVisitOutputDocument.modal.success'));
                    }

                    setOpen(false);
                },
                onError: (error) => processMutationError(error),
            });
        }
    };

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
        >
            <BottomSheetModalAppbar
                title={tt('createVisitOutputDocumentConfiguration.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <Box
                className={classes.horizontalPadding}
            >
                <FormBuilder
                    inputs={inputs}
                    setInputs={setInputs}
                />
            </Box>

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={createOutputDocument}
                    disabled={loading}
                    isLoading={submitLoading}
                >
                    {tt('common.save.create')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
