import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, InputAdornment, Theme} from "@mui/material";
import ModalBottomSheet from "../../ModalBottomSheet";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../AppData";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import {tt} from "../../../../core/Localization";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import SearchIcon from "../../../../icons/SearchIcon";
import {AddOutlined, CheckCircle} from "@mui/icons-material";
import AppButton from "../../buttons/AppButton";
import {
    GetJobTemplatesInput,
    JobTemplateResponse,
    JobTemplateResponsePage, VisitTemplateResponse
} from "../../../../generated/graphql/graphql";
import {processQueryError} from "../../../../service/ErrorService";
import {kListMaxDisplayItemsLimit} from "../../../../core/constants";
import ListShimmer from "../../shimmers/ListShimmer";
import AppListItem from "../../listItems/AppListItem";
import Icons8Template from "../../../../icons/Icons8Template";
import EmptyListText from "../../textComponents/EmptyListText";
import {useNavigate} from "react-router-dom";
import {newJobAndVisitTemplateRoute} from "../../../screens/jobs/NewJobAndVisitScreen";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    buttonContainerMobile: {
        flexDirection: "column",
    },
}));

export interface IChooseJobTemplateModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSelectTemplate: (jobTemplate: JobTemplateResponse, visitTemplates: VisitTemplateResponse[]) => void;
}

/**
 * Modal bottom sheet to choose Job (Visits) template or to edit it.
 */
export default function ChooseJobTemplateModalBottomSheet(props: IChooseJobTemplateModalBottomSheetProps) {
    const {open, setOpen, onSelectTemplate} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, isMobile} = appDataContext;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState<JobTemplateResponse>();

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('chooseJobTemplate.modal.search'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                    return {
                        ...prev,
                        search: {
                            ...prev.search,
                            value: '',
                        }
                    };
                }
            );
            setSelectedItem(undefined);
        }
    }, [open]);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<JobTemplateResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/job/template/search',
                params: {
                    companyId: companyId!,
                    search: inputs.search.value,
                    pageSize: kListMaxDisplayItemsLimit,
                } as GetJobTemplatesInput,
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId, inputs.search.value]);

    /**
     * Navigate to new or edit template route.
     */
    const handleCreateOrEditTemplate = () => {
        setOpen(false);

        if (selectedItem) {
            navigate(newJobAndVisitTemplateRoute(selectedItem.id));
        } else {
            navigate(newJobAndVisitTemplateRoute());
        }
    };

    /**
     * On select Job template, find correct Visit templates too.
     */
    const handleSelectTemplate = () => {
        const job = selectedItem!;

        const visits = data!.visits.filter((visit) => {
            return visit.jobTemplateId === job.id;
        });

        onSelectTemplate(job, visits);

        setOpen(false);
    };

    const [itemsJSX, setItemsJSX] = useState<ReactNode[]>();
    useEffect(() => {
        if (data) {
            setItemsJSX(data.jobs.map((jobTemplate) => {
                return (
                    <AppListItem
                        key={jobTemplate.id}
                        variant={"smaller-title"}
                        customAvatarInCircle={
                            <Icons8Template/>
                        }
                        title={jobTemplate.templateName}
                        actionWidget={
                            selectedItem?.id === jobTemplate.id ? <CheckCircle color={"primary"}/> : <></>
                        }
                        onClick={() => {
                            setSelectedItem(jobTemplate);
                        }}
                    />
                );
            }));
        } else {
            setItemsJSX(undefined);
        }
    }, [data, selectedItem]);

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
        >
            <Box className={classes.container}>
                <BottomSheetModalAppbar
                    onClose={() => setOpen(false)}
                    bottomContent={
                        <>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </>
                    }
                >
                    <AppButton
                        onClick={handleCreateOrEditTemplate}
                        variant={"textThin"}
                    >
                        <AddOutlined sx={{mr: 0.25}}/>
                        {tt('chooseJobTemplate.modal.newTemplate')}
                    </AppButton>
                </BottomSheetModalAppbar>

                {loading && !data ? (
                    <ListShimmer items={2}/>
                ) : (!itemsJSX || itemsJSX.length === 0) ? (
                    <EmptyListText text={tt('chooseJobTemplate.modal.emptyListMessage')}/>
                ) : itemsJSX}

                <Box sx={{pb: 10}}/>

                <Box className={cx(classes.buttonContainer, isMobile ? classes.buttonContainerMobile : undefined)}>
                    <AppButton
                        variant="outlined"
                        disabled={loading || !selectedItem}
                        fullWidth={true}
                        onClick={handleCreateOrEditTemplate}>
                        {tt('common.edit')}
                    </AppButton>

                    <AppButton
                        variant="contained"
                        disabled={loading || !selectedItem}
                        fullWidth={true}
                        onClick={handleSelectTemplate}>
                        {tt('common.choose')}
                    </AppButton>
                </Box>
            </Box>
        </ModalBottomSheet>
    );
}
