import {tt} from "../core/Localization";
import {DateTime} from "luxon";

export function getInitials(fullName: string): string {
    if (fullName.includes(' ')) {
        let strings = fullName.split(' ');
        return `${strings[0].charAt(0)} ${strings[1].charAt(0)}`;
    } else {
        return fullName.charAt(0);
    }
}

export function getFlowStepAsString(currentStep: number, totalSteps: number): string {
    return `${tt('common.flowStep')} ${currentStep} ${tt('common.flowStepOutOf')} ${totalSteps}`;
}


export function openInNewTab(url: string) {
    window.open(url, '_blank', 'noopener, noreferrer');
}

export function numberShort(value: number) {
    if (value > 1000000000) {
        return `${Math.round(value / 1000000000)} ${tt('number.abbreviation.milliard')}`;
    }
    if (value > 1000000) {
        return `${Math.round(value / 1000000)} ${tt('number.abbreviation.million')}`;
    }
    if (value > 1000) {
        return `${Math.round(value / 1000)} ${tt('number.abbreviation.thousand')}`;
    } else {
        return Math.round(value);
    }
}

export function hoursToChartLabel(value: number) {
    return `${Math.round(value)} ${tt('common.hoursAbbreviation')}`;
}

export function hoursToHoursAndMinutes(value: number) {
    return `${Math.floor(value)}${tt('common.hoursAbbreviation')} ${Math.round(value % 1 * 60)}${tt('common.minutesAbbreviation')}`;
}

export function capitalizeFirst(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Return new T[] with only unique values.
 */
export function uniqueArray<T>(array: T[]): T[] {
    const newArray: T[] = [];

    array.forEach((value) => {
        if (!newArray.includes(value)) {
            newArray.push(value);
        }
    });

    return newArray;
}

/**
 * Return new number[] with only unique values by some property.
 */
export function uniqueArrayByPredicate<T>(array: T[], predicate: (item: T, otherItem: T) => boolean): T[] {
    const newArray: T[] = [];

    array.forEach((value) => {
        if (!newArray.some((item) => predicate(item, value))) {
            newArray.push(value);
        }
    });

    return newArray;
}

/**
 * Return new number[] with only unique values.
 */
export function arrayUnique<T>(array: T[]): T[] {
    return uniqueArray<T>(array);
}

/**
 * Check if one array contains all values from another array.
 */
export function arrayContainsAll(array: number[], values: number[]): boolean {
    return values.every((value) => array.includes(value));
}

/**
 * Go through two arrays to check if they have the same contents.
 */
export function arraysEqual(array1: any[], array2: any[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Get file extension from name.
 */
export function getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
}

/**
 * Receive relative url and add current route to it as parameter.
 */
export function routeWithCurrentAsParam(route: string): string {
    const currentRoute = `${window.location.pathname}${window.location.search}`;

    let newRoute = route;

    if (newRoute.includes('?')) {
        newRoute = `${newRoute}&currentRoute=${encodeURIComponent(currentRoute)}`;
    } else {
        newRoute = `${newRoute}?currentRoute=${encodeURIComponent(currentRoute)}`;
    }

    return newRoute;
}

/**
 * Return backRoute or if current location has currentRoute param, return it.
 */
export function getBackRoute(backRoute: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    const currentRoute = urlParams.get('currentRoute');

    if (currentRoute) {
        return decodeURIComponent(currentRoute);
    }

    return backRoute;
}

/**
 * Truncate string to given length.
 */
export function truncateString(text: string, length: number, wholeWords?: string, doNotAddDots?: boolean): string {
    if (text.length <= length) {
        return text;
    }

    let truncatedText = text.slice(0, length);

    if (wholeWords) {
        const indexOfLastSpace = truncatedText.lastIndexOf(" ");

        if (indexOfLastSpace !== -1) {
            truncatedText = truncatedText.slice(0, indexOfLastSpace);
        }
    }

    if (!doNotAddDots) {
        truncatedText += "...";
    }

    return truncatedText;
}

/**
 * Convert DateTime to seconds since start of day.
 * Used for storage in DB when we do not want the time to change based on DST.
 */
export function getSecondsSinceStartOfDay(date: DateTime): number {
    return date.diff(date.startOf('day')).as('seconds');
}

/**
 * Convert seconds since start of day to DateTime.
 */
export function getDateTimeFromSecondsSinceStartOfDay(seconds: number, dateTime?: DateTime): DateTime {
    return (dateTime || DateTime.now()).startOf('day').plus({seconds});
}
