import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import {Box} from "@mui/material";
import ModalBottomSheet from "../../ModalBottomSheet";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AppButton from "../../buttons/AppButton";
import {tt} from "../../../../core/Localization";
import {IOnUpdateVisitIdParams} from "../job/visits/VisitDetailModal";
import {
    JobProtocolResponse,
    UpdateJobProtocolUnfinishedOfVisitInput,
    UpdateVisitProductMaterialsInput,
    VisitResponse
} from "../../../../generated/graphql/graphql";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../form/FormBuilder";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {processMutationError} from "../../../../service/ErrorService";
import {SuccessToast} from "../../../../service/ToastService";

export interface IEditProtocolUnfinishedToSolveModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    data: JobProtocolResponse | NullOrUndefined;
}

/**
 * Modal bottom sheet component to edit Protocol Unfinished/To solve params.
 */
export default function EditProtocolUnfinishedToSolveModalBottomSheet(props: IEditProtocolUnfinishedToSolveModalBottomSheetProps) {
    const {visitId, repeatingDay, open, setOpen, onUpdateVisitId, data} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const [unfinishedToSolveInput, setUnfinishedToSolveInput] = useState<IInputsData>({
        unfinishedToSolve: {
            testId: 'createUnfinishedToSolveSwitch',
            value: false,
            label: tt('common.unfinishedToSolve'),
            type: InputType.Switch,
            switchVariant: "Android12Switch"
        },
        unfinishedToSolveNote: {
            testId: 'createUnfinishedToSolveNote',
            value: '',
            label: `${tt('common.note')}*`,
            type: InputType.Text,
            minRows: 4,
            required: false,
            hidden: true
        },
    });

    useEffect(() => {
        if (open && data) {
            setUnfinishedToSolveInput({
                ...unfinishedToSolveInput,
                unfinishedToSolve: {
                    ...unfinishedToSolveInput.unfinishedToSolve,
                    value: data.unfinishedToSolve || false
                },
                unfinishedToSolveNote: {
                    ...unfinishedToSolveInput.unfinishedToSolveNote,
                    value: data.unfinishedToSolve ? (data.unfinishedToSolveNote || '') : ''
                }
            });
        }
    }, [open, data]);

    useEffect(() => {
        if (unfinishedToSolveInput.unfinishedToSolve.value && unfinishedToSolveInput.unfinishedToSolveNote.hidden) {
            setUnfinishedToSolveInput((prev: IInputsData) => {
                return {
                    ...prev,
                    unfinishedToSolveNote: {
                        ...prev.unfinishedToSolveNote,
                        required: true,
                        hidden: false
                    }
                };
            });
        } else if (!unfinishedToSolveInput.unfinishedToSolve.value && !unfinishedToSolveInput.unfinishedToSolveNote.hidden) {
            setUnfinishedToSolveInput((prev: IInputsData) => {
                return {
                    ...prev,
                    unfinishedToSolveNote: {
                        ...prev.unfinishedToSolveNote,
                        required: false,
                        hidden: true
                    }
                };
            });
        }
    }, [unfinishedToSolveInput.unfinishedToSolve.value]);

    const [loading, setLoading] = useState(false);
    /**
     * Update Protocol Unfinished/To solve params to BE.
     */
    const onUpdateProtocolUnfinishedToSolve = () => {
        try {
            if (ValidateForm(unfinishedToSolveInput, setUnfinishedToSolveInput)) {
                const unfinishedToSolve = unfinishedToSolveInput.unfinishedToSolve.value;
                const note = unfinishedToSolve ? unfinishedToSolveInput.unfinishedToSolveNote.value.trim() : data!.unfinishedToSolveNote;

                restApiPost({
                    uri: '/job/visit/update-protocol-unfinished',
                    params: {
                        visitId,
                        repeatingDay,
                        jobProtocolId: data!.id,
                        unfinishedToSolve,
                        unfinishedToSolveNote: note,
                    } as UpdateJobProtocolUnfinishedOfVisitInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            setOpen(false);

                            SuccessToast(
                                unfinishedToSolve ?
                                    tt('editProtocolUnfinishedToSolve.modal.success') :
                                    tt('editProtocolUnfinishedToSolve.modal.success.deactivated')
                            );

                            if (data!.id) {
                                onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: repeatingDay!,
                                });
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const disabled = unfinishedToSolveInput.unfinishedToSolve.value && !unfinishedToSolveInput.unfinishedToSolveNote.value.trim();

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            modalAboveModals={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}>
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('editProtocolUnfinishedToSolveModalBottomSheet.title')}>
            </BottomSheetModalAppbar>

            <Box pl={2} pr={2} pb={2}>
                <FormBuilder
                    inputs={unfinishedToSolveInput}
                    setInputs={setUnfinishedToSolveInput}
                />

                <Box pb={2}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={onUpdateProtocolUnfinishedToSolve}
                    isLoading={loading}
                    disabled={disabled}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
