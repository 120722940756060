import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import ModalBottomSheet from "../../ModalBottomSheet";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import AppButton from "../../buttons/AppButton";
import {CreateJobTemplateInput} from "../../../../generated/graphql/graphql";
import {processMutationError} from "../../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {SuccessToast} from "../../../../service/ToastService";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface ICreateJobTemplateModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    createInput: CreateJobTemplateInput | NullOrUndefined;
}

/**
 * Modal bottom sheet component for creating new Job (Visits) template.
 */
export default function CreateJobTemplateModalBottomSheet(props: ICreateJobTemplateModalBottomSheetProps) {
    const {open, setOpen, createInput} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const {classes} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: `${tt('createJobTemplate.modal.name')}*`,
            value: '',
            required: true,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs({
                name: {
                    ...inputs.name,
                    value: '',
                },
            });
        }
    }, [open]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Create new Job (Visits) template to BE.
     */
    const handleCreate = () => {
        try {
            if (ValidateForm(inputs, setInputs)) {
                createInput!.templateName = inputs.name.value;

                restApiPost({
                    uri: '/job/template',
                    params: {
                        ...createInput!,
                    } as CreateJobTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('createJobTemplate.modal.success'));

                            setOpen(false);
                        }
                    },
                    onError: (error: any) => processMutationError(error),
                });
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}>
            <BottomSheetModalAppbar
                title={tt('createJobTemplate.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <Box
                className={classes.horizontalPadding}
            >
                <FormBuilder
                    inputs={inputs}
                    setInputs={setInputs}
                />
            </Box>

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={handleCreate}
                    isLoading={submitLoading}
                >
                    {tt('common.save.create')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
